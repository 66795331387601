<template>
	<div class="history">
		<div class="pc_base">
			<f-heard />
			<img @click="$router.push('/')" src="../assets/menu_logo.png" class="pageLogo" alt="">
			<img src="../assets/historyBanner.png" class="historyBanner" alt="" srcset="">
			<img src="../assets/yun1.png" class="yun" alt="" />
			<p class="fp">我们的旅程始于 1939 年，老希丁（Hilding Anderson）在瑞典开设了他的第一间家具厂，并逐渐专注于床上睡眠用品的研发。</p>
			<p class="fp m44">之后希丁安集团通过多年的并购和融合, 旗下汇聚了30多个品牌, 包括区域性品牌和国际品牌，为全球消费者输送丰富的睡眠产品组合。希丁HILDING——<span style="font-weight: bold;">作为希丁安旗下创始品牌</span>，现已发展成为瑞典乃至全欧洲经销广泛的睡眠产品流行品牌。</p>
			<p class="fp m44">今天我们来到中国，期待带着我们的睡眠经验、前沿科技、与北欧美学理念，与中国市场的消费者积极碰撞，尽全力满足中国消费者的个性化需求，<span style="font-weight: bold;">让中国消费者感受每一夜的良好睡眠带来的幸福生活体验。</span></p>
			<img src="../assets/up1.png" class="up" alt="" />
			<img src="../assets/historyCente.png" class="historyCente" alt="" srcset="">
			<f-foot />
		</div>
		<div class="mobile_base">
			<m-heard />
			<img src="../assets/mobile/historyBanner.png" class="banner" alt="" srcset="">
			<p class="m_title">历史</p>
			<p class="m_title_desc">HISTORY</p>
			<p class="m_qr m60">我们的旅程始于 1939 年，老希丁（Hilding Anderson）在瑞典开设了他的第一间家具厂，并逐渐专注于床上睡眠用品的研发。</p>
			<p class="m_qr m30">之后希丁安集团通过多年的并购和融合, 旗下汇聚了30多个品牌, 包括区域性品牌和国际品牌，为全球消费者输送丰富的睡眠产品组合。希丁HILDING——<span style="font-weight: bold;">作为希丁安旗下创始品牌</span>，现已发展成为瑞典乃至全欧洲经销广泛的睡眠产品流行品牌。</p>
			<p class="m_qr m30">今天我们来到中国，期待带着我们的睡眠经验、前沿科技、与北欧美学理念，与中国市场的消费者积极碰撞，尽全力满足中国消费者的个性化需求，<span style="font-weight: bold;">让中国消费者感受每一夜的良好睡眠带来的幸福生活体验。</span></p>
			<p class="m_title m60">我们的历程</p>
			<p class="m_title_desc">HISTORY</p>
			<img src="../assets/mobile/historyCente.png" class="historyCente" alt="" srcset="">
			<m-foot />
		</div>
	</div>
</template>

<script>
	import FHeard from '@/components/FHeard'
	import FFoot from '@/components/FFoot'
	import MHeard from '@/components/MHeard'
	import MFoot from '@/components/MFoot'
	export default {
		name: 'Story',
		components: {
			FHeard,
			FFoot,
			MHeard,
			MFoot
		},
		data() {
			return {
				
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../css/History.scss';
</style>
