<template>
	<div class="index">
		<div class="pc_base">
<!--			<div class="flipbookBg" v-if="showMenu" :class="{ sflipbookBg: sMenu }"></div>-->
			<div class="flipbook-viewport" style="height: 620px;width: 1220px;">
					<div class="swiper-container" style="height: 100%" >
						<swiper v-if="banner.length > 0" :options="swiperOptionsPC" style="height: 100%">
							<swiper-slide v-for="(item,index) in banner" :style="`background-image: url(${item.img})`" :key="index">
								<div class="logo">
									<img src="../assets/i-logo.png" class="logoIcon">
								</div>
								<div class="dsc">
									<img src="../assets/menu_mesg.png" class="menu_mesg.png" alt="" srcset="">
								</div>
								<div class="menu" @click="showMask(true)">
									<p>菜单</p>
									<p style="font-family: Bliss2Bd6,Bliss2Rg10;">MENU</p>
									<img class="pre" src="../assets/pre.png" alt="" srcset="">
								</div>
								<div @click="go(item.link)" class="mores"></div>
							</swiper-slide>
							<div class="swiper-pagination-pc " slot="pagination"></div>
						</swiper>
					</div>
			</div>
			<div id="goods">
				<div v-for="(item,index) in channel" :key="index" @click="go(item.link)">
					<img :src="item.p_img" alt="" srcset="">
				</div>
<!--				<div @click="go('/product')">-->
<!--					<img src="../assets/img2.png" alt="" srcset="">-->
<!--				</div>-->
<!--				<div @click="go('/sleep')">-->
<!--					<img src="../assets/img3.png" alt="" srcset="">-->
<!--				</div>-->
<!--				<div @click="go('/news')">-->
<!--					<img src="../assets/img4.png" alt="" srcset="">-->
<!--				</div>-->
			</div>
			<div class="conceal">
				<p> ICP备案（津ICP备2023000955号） </p>
				<p> HILDING希丁安（天津）家具有限责任公司 </p>
			</div>

			<div class="mask" :class="{ smask: sMenu }" v-if="showMenu" @click="showMask(false)">
				<div class="menu_left" @click.stop="ret">
					<div class="menu_title">
						<img src="../assets/menu_logo.png" class="menu_logo" alt="">
						<div class="menu_line"></div>
						<img src="../assets/menu_logo_desc.png" class="menu_logo_desc" alt="">
						<img src="../assets/menu_close.png" class="menu_close" alt="" @click="showMask(false)">
					</div>
					<div class="items">
						<div class="item">
							<p>主页</p>
							<p class="c">主页</p>
						</div>
						<div class="item">
							<p>关于希丁</p>
							<p class="c">关于希丁</p>
							<div class="item_nav">
								<div @click="go('/story')" class="nav_item">品牌故事</div>
								<div @click="go('/history')" class="nav_item">历史</div>
								<div @click="go('/Philosophy')" class="nav_item">好睡哲学</div>
								<div @click="go('/news')" class="nav_item">新闻</div>
							</div>
						</div>
						<div class="item" @click="go('/product')">
							<p>产品中心</p>
							<p class="c">产品中心</p>
						</div>
						<div class="item" @click="go('/sleep')">
							<p>好睡实验室</p>
							<p class="c">好睡实验室</p>
						</div>
						<div class="item" @click="go('/join')">
							<p>合作加盟</p>
							<p class="c">合作加盟</p>
						</div>
						<div class="item" @click="go('/order')">
							<p>立即购买</p>
							<p class="c">立即购买</p>
						</div>
						<div class="item" @click="go('/contact')">
							<p>联系我们</p>
							<p class="c">联系我们</p>
						</div>
						<!-- <div class="item">
							<p><img src="../assets/menu_dx.png" style="width: 29px;height: 21px;" alt="" srcset=""></p>
						</div> -->
						<!-- <div class="item" @click="showSearch(true)">
							<p><img src="../assets/menu_serch.png" style="width: 22px;height: 22px;" alt="" srcset="">
							</p>
						</div> -->
					</div>
				</div>
			</div>
			<div class="index_bottom">© Hilding Anders 2023</div>
		</div>
		<div class="mobile_base">
			<m-heard />
			<div class="swiper">
				<div class="swiper-container">
					<swiper :options="swiperOptions" v-if="m_banner.length > 0">
						<swiper-slide v-for="(item,index) in m_banner" :key="index">
							<div class="banner" :style="`background-image: url('${item.img}')`">
								<p class="bannerTitle">
									源自北欧 自然美学
									<br />
									自在好眠
								</p>
								<p class="bannerDesc">
									Originally Nordic,
									<br />
									Naturally relaxed in bed
								</p>
								<img src="../assets/mobile/indeBtn.png" @click="go(item.link)" class="indexBtn" alt="">
								<img src="../assets/mobile/preIndex.png" class="preIndex" alt="" srcset="">
							</div>
						</swiper-slide>
					</swiper>
				</div>
			</div>
			<!-- <div class="banner">
				<p class="bannerTitle">
					源自北欧 自然美学
					<br />
					自在好眠
				</p>
				<p class="bannerDesc">
					Originally Nordic,
					<br />
					Naturally relaxed in bed
				</p>
				<img src="../assets/mobile/indeBtn.png" class="indexBtn" alt="">
				<img src="../assets/mobile/preIndex.png" class="preIndex" alt="" srcset="">
			</div> -->
			<!-- <div style="height: .18rem;"></div> -->
			<div class="m_goods" :class="{gm: showLoad}">
				<div class="m_good" v-for="(item,index) in channel" :key="index"  @click="go(item.link)">
					<img :src="item.m_img" alt="" srcset="">
					<!-- <p>在瑞典一座小镇的木工车间里，我们的创始人希丁·安德开设了他的第一间家具厂。</p> -->
				</div>
<!--				<div class="m_good"  @click="go('/product')">-->
<!--					<img src="../assets/mobile/m_good2.png" alt="" srcset="">-->
<!--					&lt;!&ndash; <p>在瑞典一座小镇的木工车间里，我们的创始人希丁·安德开设了他的第一间家具厂。</p> &ndash;&gt;-->
<!--				</div>-->
<!--				<div v-if="showLoad"  @click="go('/sleep')" class="m_good">-->
<!--					<img src="../assets/mobile/m_good3.png" alt="" srcset="">-->
<!--					&lt;!&ndash; <p>在瑞典一座小镇的木工车间里，我们的创始人希丁·安德开设了他的第一间家具厂。</p> &ndash;&gt;-->
<!--				</div>-->
<!--				<div v-if="showLoad" @click="go('/news')" class="m_good">-->
<!--					<img src="../assets/mobile/m_good4.png" alt="" srcset="">-->
<!--					&lt;!&ndash; <p>在瑞典一座小镇的木工车间里，我们的创始人希丁·安德开设了他的第一间家具厂。</p> &ndash;&gt;-->
<!--				</div>-->
			</div>
			<img v-if="!showLoad" @click="showLoad=true;" src="../assets/mobile/add.png" class="m_add" alt="" srcset="">
			<m-foot />
		</div>
	</div>
</template>

<script>
	import {home} from "@/api";

	let timer = null;
	import $ from 'jquery'
	import turn from '../../utils/turn.js'
	import MHeard from '@/components/MHeard'
	import MFoot from '@/components/MFoot'
	import "swiper/css/swiper.css"
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	export default {
		name: 'Index',
		data() {
			return {
				swiperOptions: {
					loop: true, //无限循环
					autoplay: {
						//自动切换
						delay: 5000,
						stopOnLastSlide: false, //切换到最后一个slide时停止自动切换
						disableOnInteraction: false, //用户操作swiper之后，是否禁止自动切换。默认为true
					},
					pagination: {
						el: ".swiper-pagination1", //若改变分页样式，需改变样式名字，默认为.swiper-pagination
						type: "bullets",
						clickable: true, //允许分页点击跳转
					},
				},
				swiperOptionsPC:{
					loop: true, //无限循环
					// autoplay: {
					// 	//自动切换
					// 	delay: 5000,
					// 	stopOnLastSlide: false, //切换到最后一个slide时停止自动切换
					// 	disableOnInteraction: false, //用户操作swiper之后，是否禁止自动切换。默认为true
					// },
					pagination: {
						el: ".swiper-pagination-pc", //若改变分页样式，需改变样式名字，默认为.swiper-pagination
						type: "bullets",
						clickable: true, //允许分页点击跳转
					},
				},
				showMenu: false,
				sMenu: false,
				showLoad: true,
				channel: [],
				banner: [],
				m_banner: []
			}
		},
		components: {
			MHeard,
			MFoot,
			swiper,
			swiperSlide
		},
		beforeDestroy(){
			clearInterval(timer);
		},
		mounted() {
			this.getHomeData()
			// Create the flipbook
			// clearInterval(timer);
			// timer = setInterval(()=>{
			// 	if(!$('.flipbook')){
			// 		clearInterval(timer)
			// 		return;
			// 	}
			// 	$('.flipbook').turn('next');
			// },3000)
			// $('.flipbook').turn({
			// 	// Width
			// 	width: 1200,
			// 	// Height
			// 	height: 580,
			// 	// Elevation
			// 	elevation: 50,
			// 	// Enable gradients
			// 	gradients: true,
			// 	// Auto center this flipbook
			// 	autoCenter: true,
			// 	page: 2,
			// 	when: {
			// 		turning: function(event, page) {
			// 			if (page === 1) {
			// 				window.history.go(0)
			// 			}
			// 			page === 1 && event.preventDefault(); // 禁止第1页时翻页
			// 			page === 10 && event.preventDefault();
			// 		},
			// 		turned: function() {
			//
			// 		},
			// 	},
			// })

			// this.showMask(true);
		},
		methods: {
			getHomeData () {
				home().then(res => {
					this.banner = res.data.banner.banner_pc
					this.m_banner = res.data.banner.banner_mobile
					this.channel = res.data.channel
				})
			},
			showSearch(flag){
				// console.log(11);
				//this.$parent.serch = true;
				this.$store.state.search = flag;
			},
			disn(page){

				return;
				clearInterval(timer);
				console.log(page);
				$('.flipbook').turn('page', page + 1);
				timer = setInterval(()=>{
					$('.flipbook').turn('next');
				},3000)
			},
			cleTime(){
				clearInterval(timer);
				timer = setInterval(()=>{
					$('.flipbook').turn('next');
				},3000)
			},
			ret() {

			},
			go(url) {
				if (url !== '' && url !== null && url !== undefined) {
					if (this.containsHttp(url)) {
						window.location.href = url
					} else {
						this.$router.push(url)
					}


				}
			},
			containsHttp(text) {
				return text.includes("https://") || text.includes("http://");
			},
			showMask(flag) {
				if (flag) {
					this.showMenu = true;
					setTimeout(() => {
						this.sMenu = true;
					}, 50)
				} else {
					this.sMenu = false;
					setTimeout(() => {
						this.showMenu = false;
					}, 500)
				}

			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../css/Index.scss';
</style>
