<template>
	<div class="foot">
		<img src="../assets/mobile/m_logo.png" class="foot-logo" alt="">
		<div class="foot-menu">
			<p @click="go('/')">主页</p>
			<p @click="go('/join')">合作加盟</p>
			<p @click="go('/story')">关于希丁</p>
			<p @click="go('/order')">立即购买</p>
			<p @click="go('/product')">产品中心</p>
			<p @click="go('/join')">加入我们</p>
			<p @click="go('/sleep')">好睡实验室</p>
			<p @click="go('/contact')">联系我们</p>
		</div>

		<div class="icon">
			<img v-for="(item,index) in followList" @click="showQrcode(item)" :key="index" :src="item.icon" alt="">
<!--			<img src="../assets/mobile/icon2.png" alt="">-->
<!--			<img @click="showQrcode(true)" src="../assets/mobile/icon3.png" alt="">-->
<!--			<img src="../assets/mobile/icon4.png" alt="">-->
		</div>

		<div class="ous" @click="go('/contentPrivacy')">
			隐私条款
			<br />
			ICP备案（津ICP备2023000955号）
			<br />
			HILDING希丁安（天津）家具有限责任公司
		</div>

		<img src="../assets/mobile/m_bottom_bg.png" class="m_bottom_bg" alt="" srcset="">
		<div class="qrcode" v-if="showQr" @click="hideQrcode(false)" :class="{a: showQrs}">
			<div class="qrcode_info" @click.stop="rt">
				<img @click="hideQrcode(false)" src="../assets/mobile/close.png" class="close" alt="">
				<div class="qrcode_title">更多详情请扫码添加<br />{{ showQrInfo.tip }}</div>
				<img :src="showQrInfo.content" class="qrodeIcon" alt="" srcset="">
			</div>
		</div>
	</div>

</template>

<script>
import {follow} from "@/api";
	export default {
		data(){
			return {
				showQr: false,
				showQrs: false,
				followList: [],
				showQrInfo: {},
			}
		},
		mounted() {
			this.getFollow()
		},
		methods:{
			rt(){

			},
			// 获取分享
			getFollow() {
				follow().then(res => {
					console.log(res)
					this.followList = res.data
				})
			},
			go(url){
				this.$router.push(url)
			},
			showQrcode(item){
				if (item.type === 'link') {
					window.location.href = item.content
				} else {
					console.log('点击')
					this.showQr = true;
					this.showQrInfo = item
					setTimeout(() => {
						this.showQrs = true;
					}, 10);
				}
			},
			hideQrcode() {
				this.showQrs = false;
				setTimeout(() => {
					this.showQr = false;
				}, 300);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.foot{
		width: 100vw;
		height: 6.6rem;
		background: #EEE;
		box-sizing: border-box;
		padding: .46rem 0 0 .4rem;
		position: relative;
		font-family: "AlibabaPuHuiTiM";

		.qrcode{
			position: fixed;
			opacity: 0;
			left: 0;
			top: 0;
			z-index: 99999;
			width: 100vw;
			height: 100vh;
			background: rgba(0, 51, 95, .6);
			backdrop-filter: blur(6px);
			transition: all .3s;
			&.a{
				opacity: 1;
			}
			.qrcode_info{
				width: 6.54rem;
				//height: 7.04rem;
				background: #FFFFFF;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				padding: .83rem 0 0 0;
				.close{
					position: absolute;
					top: .46rem;
					right: .5rem;
					width: .26rem;
					height: .26rem;
				}
				.qrcode_title{
					text-align: center;
					font-family: "AlibabaPuHuiTiM";
					color: #5E717A;
					line-height: .45rem;
					letter-spacing: .06rem;
					font-size: 12px;
				}
				.qrodeIcon{
					width: 4.3rem;
					//height: 4.3rem;
					margin: .1rem auto;
				}
			}
		}

		.m_bottom_bg{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
		}
		.ous{
			margin-top: .4rem;
			font-size: .16rem;
			color: #5E717A;
			line-height: .3rem;
			letter-spacing: .05rem;
			cursor: pointer;
		}
		.icon{
			margin-top: .2rem;
			display: flex;
			align-items: center;
			img{
				flex: none;
				width: .34rem;
				margin-right: .5rem;
			}
		}
		.foot-logo{
			width: 1.84rem;
			height: .49rem;
			display: block;
		}
		.foot-menu{
			font-size: .2rem;
			color: #5E717A;
			display: flex;
			margin-top: .3rem;
			flex-wrap: wrap;
			p{
				line-height: .7rem;
				letter-spacing: .06rem;
				flex: none;
				width: 50%;
			}

		}
	}
</style>
