import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Story from '../views/Story.vue'
import History from '../views/History.vue'
import Philosophy from '../views/Philosophy.vue'
import Product from '../views/Product.vue'
import Sleep from '../views/Sleep.vue'
import Contact from '../views/Contact.vue'
import Join from '../views/Join.vue'
import Order from '../views/Order.vue'
import News from '../views/News.vue'
import NewsDetails from '../views/NewsDetails.vue'
import contentPrivacy from '../views/content-privacy.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/story',
    name: 'story',
    component: Story
  },
  {
    path: '/history',
    name: 'history',
    component: History
  },
  {
    path: '/product',
    name: 'product',
    component: Product
  },
  {
    path: '/philosophy',
    name: 'philosophy',
    component: Philosophy
  },
  {
    path: '/sleep',
    name: 'sleep',
    component: Sleep
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/join',
    name: 'join',
    component: Join
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/order',
    name: 'order',
    component: Order
  },
  {
	  path: '/newsDetails',
	  name: 'newsDetails',
	  component: NewsDetails
  },
  {
  	  path: '/contentPrivacy',
  	  name: 'contentPrivacy',
  	  component: contentPrivacy
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(){
	  return { x:0, y:0 }
  }
})

export default router
