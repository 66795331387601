<template>
	<div id="app">
		<div class="m_search_top" @click="showSearch(false)" :class="{ a:search }">
			<div @click.stop="rt" class="m_search_inp">
				<input type="text" />
				<img src="./assets/menu_serch_b.png" class="m_search_top_Icon" alt="" srcset="">
			</div>
		</div>

		<div class="p_search_top" @click="showSearch(false)" :class="{ a:search }">
			<div @click.stop="rt" class="p_search_inp">
				<input type="text" />
				<img src="./assets/menu_serch_b.png" class="p_search_top_Icon" alt="" srcset="">
			</div>
		</div>
		<router-view />
	</div>
</template>

<script>
	import $ from 'jquery'
	import {mapState} from 'vuex';
	export default {
		computed: mapState({ search: state => state.search }),
		data(){
			return {

			}
		},
		created() {
			!(function(n) {
				var e = n.document,
					t = e.documentElement,
					i = 750,
					d = i / 100,
					o = "orientationchange" in n ? "orientationchange" : "resize",
					a = function() {
						if (t.clientWidth <= 1024) {
							var n = t.clientWidth || 320;
							n > 750 && (n = 750);
							t.style.fontSize = n / d + "px";
						} else {
							// t.style = "";
							$(t).attr('style', '');
						}
					};
				e.addEventListener &&
					(n.addEventListener(o, a, !1),
						e.addEventListener("DOMContentLoaded", a, !1));
			})(window);
			!(function(n) {
				var e = n.document,
						t = e.documentElement,
						i = 1920,
						d = i / 100,
						o = "orientationchange" in n ? "orientationchange" : "resize",
						a = function() {
							if (t.clientWidth > 1024) {
								var n = t.clientWidth || 1920;
								n > 1920 && (n = 1920);
								t.style.fontSize = n / d + "px";
							} else {
								// t.style = "";
								// $(t).attr('style', '');
							}
						};
				e.addEventListener &&
				(n.addEventListener(o, a, !1),
						e.addEventListener("DOMContentLoaded", a, !1));
			})(window);
		},
		methods:{
			rt(){

			},
			showSearch(flag){
				this.$store.state.search = flag;
			}
		}
	}
</script>

<style lang="scss">
	@import "./common/base.css";

	.p_search_top {
		position: fixed;
		width: 100%;
		height: 100vh;
		left: 0;
		padding: 20px 20px 0;
		box-sizing: border-box;
		right: 0;
		white-space: nowrap;
		z-index: 9999;
		top: -100%;
		display: flex;
		transition: all .5s;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.01) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cc000000", endColorstr="#1a000000", GradientType=0);
		.p_search_inp{
			display: flex;
			width: 100%;
			height: 88px;
			align-items: center;
			justify-content: center;
		}
		input{
			padding: 20px 10px;
			width: 88%;
			border-bottom: 1px solid #bfbfbf;
			font-size: 24px;
			display: block;
			flex: none;
			height: 88px;
			color: #fff;
			font-family: "AlibabaPuHuiTiM";
		}
		.p_search_top_Icon{
			width: 33px;
			height: 33px;
			margin: 20px 0 0 24px;
			cursor: pointer;
		}
		&.a {
			top: 0;
			opacity: 1;
			visibility: visible;
		}
	}


	.m_search_top {
		position: fixed;
		width: 100%;
		height: 100vh;
		left: 0;
		padding: .2rem .2rem 0;
		box-sizing: border-box;
		right: 0;
		white-space: nowrap;
		z-index: 9999;
		top: -100vh;
		display: flex;
		transition: all .5s;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 0.01) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#cc000000", endColorstr="#1a000000", GradientType=0);
		.m_search_inp{
			display: flex;
			width: 100%;
			height: .88rem;
		}
		input{
			padding: .2rem .1rem;
			width: 88%;
			border-bottom: 1px solid #bfbfbf;
			font-size: .32rem;
			display: block;
			flex: none;
			height: .88rem;
			color: #fff;
			font-family: "AlibabaPuHuiTiM";
		}
		.m_search_top_Icon{
			width: .44rem;
			height: .44rem;
			margin: .2rem 0 0 .24rem;
		}
		&.a {
			top: 0;
			opacity: 1;
			visibility: visible;
		}
	}
</style>
