import request from '@/utils/request'

// 首页数据  /api/V1/home
export const home = (data) => {
    return request({
        url: '/api/V1/home',
        method: 'POST',
        data
    })
}
// 新闻列表
export const news_list = (data) => {
    return request({
        url: '/api/V1/news_list',
        method: 'POST',
        data
    })
}
// 新闻详情
export const news = (data) => {
    return request({
        url: '/api/V1/news',
        method: 'POST',
        data
    })
}
// 联系我们提交  /api/V1/join_us
export const join_us = (data) => {
    return request({
        url: '/api/V1/join_us',
        method: 'POST',
        data
    })
}
// 联系我们 /api/V1/contact_us
export const contact_us = (data) => {
    return request({
        url: '/api/V1/contact_us',
        method: 'POST',
        data
    })
}
// 分享
export const follow = (data) => {
    return request({
        url: '/api/V1/follow',
        method: 'POST',
        data
    })
}
// 获取地址
export const shop = (data) => {
    return request({
        url: '/api/V1/shop',
        method: 'POST',
        data
    })
}
// 获取富文本/api/V1/page?p=product
export const page = (data) => {
    return request({
        url: '/api/V1/page?p=product',
        method: 'GET',
        data
    })
}
