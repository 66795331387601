<template>
	<div class="news newsdetailsd" v-if="detailsInfo">
		<div class="pc_base">
			<f-heard />
			<img @click="$router.push('/')" src="../assets/menu_logo.png" class="pageLogo" alt="">
			<img src="../assets/newsBanner.png" class="newsBanner" alt="" srcset="">
			<div class="news-title">{{ detailsInfo.title }}</div>
			<img src="../assets/yun1.png" class="yun" alt="" />
<!--			class="fp"-->
			<div v-html="detailsInfo.p_html" class="fp">
<!--				<p class="fp">2019年8月，希丁安集团旗下旗舰品牌-瑞典卡缇梦寝具（Carpe Diem Beds）中国首家体验展厅将在北京居然家之尊隆重亮相！</p>-->
<!--				<p class="fp ">Lysekil-一个位于北欧瑞典哥德堡附近的魅力海湾，瑞典奢华零重力睡眠体验的代表品牌- Carpe Diem Beds，就诞生在这里。</p>-->
<!--				<img src="../assets/up1.png" class="up" alt="" />-->
<!--				<img src="../assets/newsdetails.png" class="newsdetails" alt="">-->
<!--				<p class="fp">2019年，卡缇梦寝具(Carpe Diem Beds)品牌将聚焦于通过旗舰概念商店、零售合作以及特许经营模式等方式扩大数字化足迹和零售系统，从而扩大品牌的创造价值，为最终消费者提供优质服务。这次在北京开设首店，希丁安集团希望通过旗下这一高端品牌的引进，为中国消费者提供更高端和更加完美的睡眠体验。并将为卡缇梦寝具(Carpe Diem Beds)在中国扩大品牌影响力、加强品牌吸引力产生重要作用，是希丁安集团在中国的重要战略部署。</p>-->
<!--				<p class="fp m43">希丁安集团简介：希丁安集团成立于1939年，是欧洲亚洲及俄罗斯领先的寝具生产商，在全球拥有约24家工厂，超过8000名员工，业务遍及欧亚40多个国家及地区，全球最大寝具生产商之一。旗下拥有Jensen，瑞典Carpe Diem Beds卡缇梦，瑞典Hilding希丁，瑞士BICO瑞柯，英国Slumberland斯林百兰等众多高端品牌。</p>-->
<!--				<p class="fp m43">Giving the world a good night’s sleep将美好睡眠送达全球&#45;&#45;是希丁安集团的企业愿景。</p>-->
<!--				<p class="fp m43">欢迎诸位莅临</p>-->
<!--				<p class="fp ">卡缇梦寝具(Carpe Diem Beds)中国首店开幕式</p>-->
<!--				<p class="fp " style="margin-bottom: 90px;">地址：北京朝阳区北四环东路65号的居然之家-家之尊DS5馆6楼019号</p>-->
			</div>
<!--			<div v-if="id == 1">-->
<!--				<p class="fp" style="text-align: left;">-->
<!--					近日，总部位于瑞典的希丁安寝具集团( Hilding Anders Group)携旗下三大品牌——希丁HILDING、JENSEN及斯林百兰 ——参加了在上海新国际博览中心举行的2023年上海国际酒店工程家具及商业空间设计展。这次参展不仅展示了公司最新的高品质床垫和睡眠产品，也体现了瑞典希丁安集团对中国市场的重视和其持续投资中国的发展战略。-->
<!--					</p><p class="fp m43" style="text-align: left;">-->
<!--					  成立于1939年的瑞典希丁安集团是一家集研发、生产、销售于一体的床垫和床品制造商，以其高品质的产品和服务在海外市场享有盛誉。希丁安集团在欧洲、亚洲、南美洲和中东地区均设有生产基地和销售网络，产品覆盖床垫、床架、沙发等多个品类，是全球床垫市场规模庞大的企业之一。-->
<!--					</p><p class="fp m43" style="text-align: left;">-->
<!--					  在现场，希丁安集团中国区负责人为现场的酒店集团代表讲述了希丁安集团的使命：“我们坚信高质量的睡眠是健康快乐生活的基础, 我们一直致力于为全球消费者提供安心舒适的睡眠体验来改善人们的生活质量和提高家庭的幸福感。自1939年以来，我们一直按照瑞典工艺传统和高品质的标准研发和制造我们的床垫。多年来，我们的品牌已成为欧洲几代人喜爱的床垫品牌，这是令我们感到高兴和自豪的事情。”-->
<!--					</p><p class="fp m43" style="text-align: left;">-->
<!--					  在展会现场，希丁HILDING品牌展示了其最新研发的一系列中高端床垫。所有产品原料皆以欧洲同标准制造：床垫皆经过人体工程学设计测试，更是在位于瑞士的希丁安集团睡眠实验室（Sleep-Lab）中通过了上百道专业测试。-->
<!--					</p><p class="fp m43" style="text-align: left;">-->
<!--					  JENSEN是希丁安集团旗下挪威知名的高端奢华床垫品牌之一，至今已有100多年的历史，以其独特的制造工艺和精致的手工艺术而闻名。该品牌的产品被广泛应用于酒店、度假村和高端住宅，并受到世界各地的消费者的认可和青睐。-->
<!--					</p><p class="fp m43" style="text-align: left;">-->
<!--					  创立于1919年的斯林百兰品牌进入亚洲市场已经超过40年时间，其特有的PPS连锁睡姿弹簧可以确保弹簧可以按照脊椎的曲线而调节，并采用独有袋装减压式设计，令人在睡眠时可以自由翻动，提升深度睡眠质量。-->
<!--					</p><p class="fp m43" style="text-align: left;">-->
<!--					  在现场，希丁HILDING品牌销售负责人童戎戎说道：“希丁HILDING床垫两大核心技术：HILDING Box-packed边缘支撑技术和一体压缩卷装技术在床垫产品体验上大幅提升，这项技术也帮助酒店客户提升了竞争力，给酒店集团和业主在运输和搬运上节约了大量的人力、物力、财力，极大的优化了成本。”-->
<!--					</p><p class="fp m43" style="text-align: left;">-->
<!--					  关于集团其他品牌，他表示：“挪威JENSEN品牌我们会在国内市场保持奢华品牌的定位，产品原装进口，独特的智能控制系统以及领先的ALOY3.0专利弹簧系统，可以实现双人根据不同身体的睡姿调整软硬，以达到“无中断睡眠”的JENSEN品牌理念。斯林百兰作为最早进入中国市场的床垫品牌之一，我们会继续保持斯林百兰的高端品牌定位，继续扩大在高星级酒店床垫市场的份额，同时不断迭代新品，继续给消费提供高品质的产品。”-->
<!--					</p><p class="fp m43" style="text-align: left;margin-bottom: 90px;">-->
<!--					  据悉，希丁安集团在展会中也强调了其在中国市场的发展战略和承诺，表示将不断提升产品和服务的质量和标准，为中国消费者提供更加优质的睡眠体验。相信在希丁安集团的不断努力下，中国消费者将会享受到更加优质睡眠产品，同时，其可持续发展和高标准的品牌理念也会引领行业新的发展方向。-->
<!--				</p>-->
<!--			</div>-->
			<f-foot />
		</div>
		<div class="mobile_base">
			<m-heard />
			<img src="../assets/mobile/detailsBanner.png" class="banner" alt="" srcset="">
			<p class="m_title">{{ detailsInfo.title }}</p>
			<p class="m_title_desc">news</p>
			<div v-html="detailsInfo.m_html" class="m_qr">
<!--				<p class="m_qr m60" style="text-align: left;">-->
<!--					近日，总部位于瑞典的希丁安寝具集团( Hilding Anders Group)携旗下三大品牌——希丁HILDING、JENSEN及斯林百兰 ——参加了在上海新国际博览中心举行的2023年上海国际酒店工程家具及商业空间设计展。这次参展不仅展示了公司最新的高品质床垫和睡眠产品，也体现了瑞典希丁安集团对中国市场的重视和其持续投资中国的发展战略。-->
<!--					</p><p class="m_qr m43" style="text-align: left;">-->
<!--					  成立于1939年的瑞典希丁安集团是一家集研发、生产、销售于一体的床垫和床品制造商，以其高品质的产品和服务在海外市场享有盛誉。希丁安集团在欧洲、亚洲、南美洲和中东地区均设有生产基地和销售网络，产品覆盖床垫、床架、沙发等多个品类，是全球床垫市场规模庞大的企业之一。-->
<!--					</p><p class="m_qr m43" style="text-align: left;">-->
<!--					  在现场，希丁安集团中国区负责人为现场的酒店集团代表讲述了希丁安集团的使命：“我们坚信高质量的睡眠是健康快乐生活的基础, 我们一直致力于为全球消费者提供安心舒适的睡眠体验来改善人们的生活质量和提高家庭的幸福感。自1939年以来，我们一直按照瑞典工艺传统和高品质的标准研发和制造我们的床垫。多年来，我们的品牌已成为欧洲几代人喜爱的床垫品牌，这是令我们感到高兴和自豪的事情。”-->
<!--					</p><p class="m_qr m43" style="text-align: left;">-->
<!--					  在展会现场，希丁HILDING品牌展示了其最新研发的一系列中高端床垫。所有产品原料皆以欧洲同标准制造：床垫皆经过人体工程学设计测试，更是在位于瑞士的希丁安集团睡眠实验室（Sleep-Lab）中通过了上百道专业测试。-->
<!--					</p><p class="m_qr m43" style="text-align: left;">-->
<!--					  JENSEN是希丁安集团旗下挪威知名的高端奢华床垫品牌之一，至今已有100多年的历史，以其独特的制造工艺和精致的手工艺术而闻名。该品牌的产品被广泛应用于酒店、度假村和高端住宅，并受到世界各地的消费者的认可和青睐。-->
<!--					</p><p class="m_qr m43" style="text-align: left;">-->
<!--					  创立于1919年的斯林百兰品牌进入亚洲市场已经超过40年时间，其特有的PPS连锁睡姿弹簧可以确保弹簧可以按照脊椎的曲线而调节，并采用独有袋装减压式设计，令人在睡眠时可以自由翻动，提升深度睡眠质量。-->
<!--					</p><p class="m_qr m43" style="text-align: left;">-->
<!--					  在现场，希丁HILDING品牌销售负责人童戎戎说道：“希丁HILDING床垫两大核心技术：HILDING Box-packed边缘支撑技术和一体压缩卷装技术在床垫产品体验上大幅提升，这项技术也帮助酒店客户提升了竞争力，给酒店集团和业主在运输和搬运上节约了大量的人力、物力、财力，极大的优化了成本。”-->
<!--					</p><p class="m_qr m43" style="text-align: left;">-->
<!--					  关于集团其他品牌，他表示：“挪威JENSEN品牌我们会在国内市场保持奢华品牌的定位，产品原装进口，独特的智能控制系统以及领先的ALOY3.0专利弹簧系统，可以实现双人根据不同身体的睡姿调整软硬，以达到“无中断睡眠”的JENSEN品牌理念。斯林百兰作为最早进入中国市场的床垫品牌之一，我们会继续保持斯林百兰的高端品牌定位，继续扩大在高星级酒店床垫市场的份额，同时不断迭代新品，继续给消费提供高品质的产品。”-->
<!--					</p><p class="m_qr m43" style="text-align: left;margin-bottom: 90px;">-->
<!--					  据悉，希丁安集团在展会中也强调了其在中国市场的发展战略和承诺，表示将不断提升产品和服务的质量和标准，为中国消费者提供更加优质的睡眠体验。相信在希丁安集团的不断努力下，中国消费者将会享受到更加优质睡眠产品，同时，其可持续发展和高标准的品牌理念也会引领行业新的发展方向。-->
<!--				</p>-->
			</div>
<!--			<div v-if="id==0">-->
<!--				<p class="m_qr m60">2019年8月，希丁安集团旗下旗舰品牌-瑞典卡缇梦寝具（Carpe Diem Beds）中国首家体验展厅将在北京居然家之尊隆重亮相！ </p>-->
<!--				<p class="m_qr m60">Lysekil-一个位于北欧瑞典哥德堡附近的魅力海湾，瑞典奢华零重力睡眠体验的代表品牌- Carpe Diem Beds，就诞生在这里。</p>-->
<!--				<img v-if="!load" @click="load = true;" src="../assets/mobile/add.png" class="m_add" alt="" srcset="">-->

<!--				<div v-if="load" class="swiper">-->
<!--					<div class="swiper-container">-->
<!--						<swiper :options="swiperOptions">-->
<!--							<swiper-slide>-->
<!--								<div class="container">-->
<!--									<img class="swiperSleepBg" src="../assets/mobile/detailsone.png" alt="" srcset="">-->
<!--									<div class="swiper-bottom swiper-bottom1">-->
<!--										<p class="containerTips">使命<br />Mission</p>-->
<!--									</div>-->
<!--								</div>-->
<!--							</swiper-slide>-->

<!--							<swiper-slide>-->
<!--								<div class="container">-->
<!--									<img class="swiperSleepBg" src="../assets/mobile/detailsone.png" alt="" srcset="">-->
<!--									<div class="swiper-bottom swiper-bottom2">-->
<!--										<p class="containerTips">使命<br />Mission</p>-->
<!--									</div>-->
<!--								</div>-->
<!--							</swiper-slide>-->

<!--							<swiper-slide>-->
<!--								<div class="container">-->
<!--									<img class="swiperSleepBg" src="../assets/mobile/detailsone.png" alt="" srcset="">-->
<!--									<div class="swiper-bottom swiper-bottom3">-->
<!--										<p class="containerTips">使命<br />Mission</p>-->
<!--									</div>-->
<!--								</div>-->
<!--							</swiper-slide>-->

<!--							<swiper-slide>-->
<!--								<div class="container">-->
<!--									<img class="swiperSleepBg" src="../assets/mobile/detailsone.png" alt="" srcset="">-->
<!--									<div class="swiper-bottom swiper-bottom4">-->
<!--										<p class="containerTips">使命<br />Mission</p>-->
<!--									</div>-->
<!--								</div>-->
<!--							</swiper-slide>-->
<!--						</swiper>-->
<!--						<div class="swiper-pagination1" slot="pagination"></div>-->
<!--					</div>-->
<!--				</div>-->

<!--				<p v-if="load" class="m_qr m80">“传统的寝具注重并且强调它在卧室中的睡眠功能，但我们想说的是，寝具不只是用来睡觉，它还是与人有着最强人际关系的家具，这是一个事实。我们希望卡缇梦拥有忠实的粉丝群体，他们将为拥有卡缇梦而感到骄傲。”卡缇梦寝具(Carpe Diem Beds)商业总监Linda Schori这样说。</p>-->
<!--				<img v-if="load" src="../assets/mobile/detailstwo.png" class="detailstwo m60"  alt="" srcset="">-->

<!--				<p v-if="load" class="m_qr">2019年，卡缇梦寝具(Carpe Diem Beds)品牌将聚焦于通过旗舰概念商店、零售合作以及特许经营模式等方式扩大数字化足迹和零售系统，从而扩大品牌的创造价值，为最终消费者提供优质服务。</p>-->

<!--				<p v-if="load" class="m_qr m60" style="margin-bottom: 1.76rem;">这次在北京开设首店，希丁安集团希望通过旗下这一高端品牌的引进，为中国消费者提供更高端和更加完美的睡眠体验。并将为卡缇梦寝具(Carpe Diem Beds)在中国扩大品牌影响力、加强品牌吸引力产生重要作用，是希丁安集团在中国的重要战略部署。</p>-->
<!--			</div>-->

			<m-foot />
		</div>
	</div>
</template>

<script>
	import FHeard from '@/components/FHeard'
	import FFoot from '@/components/FFoot'
	import MHeard from '@/components/MHeard'
	import MFoot from '@/components/MFoot'
	import "swiper/css/swiper.css"
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	import {news} from "@/api";
	export default{
		name: 'news',
		components: {
			FHeard,
			FFoot,
			MHeard,
			MFoot,
			swiper,
			swiperSlide
		},

		data() {
			return {
				id: "",
				load: true,
				swiperOptions: {
					loop: true, //无限循环
					autoplay: {
						//自动切换
						delay: 5000,
						stopOnLastSlide: false, //切换到最后一个slide时停止自动切换
						disableOnInteraction: false, //用户操作swiper之后，是否禁止自动切换。默认为true
					},
					pagination: {
						el: ".swiper-pagination1", //若改变分页样式，需改变样式名字，默认为.swiper-pagination
						type: "bullets",
						clickable: true, //允许分页点击跳转
					},
				},
				detailsInfo: {}
			}
		},
		mounted(){
			// console.log(this.$route.query.id)
			this.id = this.$route.query.id;
			this.getDetails()
		},
		methods: {
			getDetails() {
				news({ id: this.id }).then(res => {
					console.log(res)
					this.detailsInfo = res.data
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../css/News.scss';


	::v-deep .newsdetailsd{
		.fp{
			width: 1417px;
			max-width: 90%;
		}
		.m43{
			margin-top: 43px;
		}

		.mobile_base{
			.m80{
				margin-top: .8rem;
			}
			.detailstwo{
				width: 6.54rem;
				height: 3.53rem;
				display: block;
				margin: .6rem auto .6rem;
			}
			.m_add{
				margin: .62rem auto .72rem;
				width: .32rem;
				height: .32rem;
				display: block;
				opacity: .6;
			}

			.swiper{
				margin: .8rem 0 1rem 0;
				position: relative;
				height: 6.87rem;
				.containerTips{
					position: absolute;
					bottom: .20rem;
					line-height: .30rem;
					left: 1.96rem;
					font-weight: 500;
					color: #FFFFFF;
					font-size: .28rem;
				}
				.swiperSleepBg{
					position: absolute;
					left: 0;
					top: 0;
					width: 5.68rem;
					height: 5.41rem;
					z-index: 2;
				}
				.container{
					padding-top: 0;
				}
				.swiper-bottom{
					height: 6.87rem;
					width: 5.87rem;
					float: right;
					&.swiper-bottom1{
						background: #007BC1;
					}
					&.swiper-bottom2{
						background: #B6D174;
					}
					&.swiper-bottom3{
						background: #968569;
					}
					&.swiper-bottom4{
						background: #738087;
					}
					padding: 1.6rem .48rem 0 .5rem;
					// padding-left: .5rem;
					box-sizing: border-box;

					p{
						margin-top: .28rem;
						color: #FFFFFF;
						line-height: .45rem;
						letter-spacing: .06rem;
						font-family: AlibabaPuHuiTiM;
						font-size: 12px;
					}
				}
			}
			.swiper-pagination1{
				display: flex;
				align-items: center;
				justify-content: center;
				position: absolute;
				bottom: 1.14rem;
				// top: 2.06rem;
				z-index: 2;
				left: 50%;
				transform: translateX(-50%);
				.swiper-pagination-bullet{
					background: #fff !important;
					opacity: .5;
					margin-right: .2rem;
					&:last-child{
						margin-right: 0;
					}
					&.swiper-pagination-bullet-active{
						opacity: 1;
						width: .32rem;
						border-radius: 10px;
					}
				}
			}
		}
	}

</style>
