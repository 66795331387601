<template>
	<div>
		<img @click="setShow(true)" src="../assets/p-menu.png" class="p-menu" alt="" srcset="">
		<div class="mask" v-if="show" @click="setShow(false)">
			<div class="mask_menu" @click.stop="ret" :class="{masks:showInfo}">
				<div @click="go('/')" class="item">
					<p>主页</p>
				</div>
				<div @click="go('/story')" class="item">
					<p>关于希丁</p>
					<div class="item_nav">
						<div @click="go('/story')" class="nav_item">品牌故事</div>
						<div @click="go('/history')" class="nav_item">历史</div>
						<div @click="go('/Philosophy')" class="nav_item">好睡哲学</div>
						<div @click="go('/news')" class="nav_item">新闻</div>
					</div>
				</div>
				<div @click="go('/product')" class="item">
					<p>产品中心</p>
				</div>
				<div @click="go('/sleep')" class="item">
					<p>好睡实验室</p>
				</div>
				<div @click="go('/join')" class="item">
					<p>合作加盟</p>
				</div>
				<div @click="go('/order')" class="item">
					<p>立即购买</p>
				</div>
				<div  @click="go('/contact')" class="item">
					<p>联系我们</p>
				</div>
				<!-- <div class="item">
					<span><img src="../assets/menu_dx_b.png" style="width: 29px;height: 21px;" alt="" srcset=""></span>
				</div> -->
				<!-- <div  class="item">
					<span><img @click="showSearch(true)" src="../assets/menu_serch_b.png" style="width: 22px;height: 22px;" alt="" srcset=""></span>
				</div> -->
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		data(){
			return {
				show: false,
				showInfo: false
			}
		},
		methods:{
			go(url){
				this.$router.push(url)
			},
			ret(){

			},
			showSearch(flag){
				// console.log(11);
				//this.$parent.serch = true;
				this.$store.state.search = flag;
			},
			setShow(flag){
				if(flag){
					this.show = true;
					setTimeout(()=>{
						this.showInfo = true;
					}, 50)
				}else{
					this.showInfo = false;
					setTimeout(()=>{
						this.show = false;
					}, 500)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>

	.p-menu{
		position: absolute;
		// left: 76px;
		left: 3.9%;
		// top: 170px;
		top: 12.8%;
		// width: 34px;
		width: 1.7%;
		// height: 24px;
		height: auto;
		cursor: pointer;
	}
	@media screen and (min-width: 1920px){
	  .p-menu{
		  top: 170px;
	  }
	}
	@media screen and (min-width: 1562px){
	  .p-menu{
		  top: 15.8%;
	  }
	}
	.mask{
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 9;
		.mask_menu{
			width: 232px;
			// height: 646px;
			padding-bottom: 20px;
			background: #5096d0;
			position: absolute;
			left: -232px;
			top: 140px;
			display: flex;
			flex-direction: column;
			padding-top: 20px;
			letter-spacing: 4px;
			transition: all .5s;
			&.masks{
				left: 0;
			}
			.item{
				display: flex;
				align-items: center;
				margin-top: 10px;
				// justify-content: center;
				padding-left: 70px;
				box-sizing: border-box;
				font-family: "AlibabaPuHuiTiR";
				font-size: 16px;
				&:first-child{
					margin-top: 0;
				}
				position: relative;

				.item_nav{
					transition: all .5s;
					opacity: 1;
					position: absolute;
					right: -170px;
					width: 170px;
					top: 10px;
					box-sizing: border-box;
					opacity: 0;
					display: none;
					.nav_item{
						box-sizing: border-box;
						height: 54px;
						width: 170px;
						display: flex;
						cursor: pointer;
						align-items: center;
						justify-content: center;
						background: #80b9e9;
						border-right: 1px solid #80b9e9;
						color: #fff;
						&:hover{
							background: #fff;
							color: #00335f;
						}
					}
				}
				&:hover{
					.c{
						opacity: 1;
					}
					.item_nav{
						opacity: 1;
						display: block;
					}
				}

				span{
					display: inline-block;
					flex: none;
					height: 60px;
					line-height: 60px;
					cursor: pointer;
					color: #fff;
					img{
						display: inline-block;
					}
				}
				p{
					display: inline-block;
					flex: none;
					height: 60px;
					line-height: 60px;
					cursor: pointer;
					color: #fff;
					img{
						display: inline-block;
					}
					&:hover{
						border-bottom: 1px solid #cee1f0;
					}
					// max-width: 110rpx;
				}
			}
		}
	}
</style>
