<template>
	<div class="contact">
		<div class="pc_base">
			<f-heard />
			<img @click="$router.push('/')" src="../assets/menu_logo.png" class="pageLogo" alt="">
			<img src="../assets/contactBanner.png" class="contactBanner" alt="" srcset="">
			<img src="../assets/yun4.png" class="yun" alt="" />
			<!-- <p class="fp">请输入您的联系方式及疑问，我们将尽快与您取得联络！</p> -->
			<!-- <img src="../assets/up4.png" class="up" alt="" /> -->

			<!-- <div class="phone">
				<div class="left">
					<div class="tips">联系电话</div>
					<input type="tel" placeholder="请输入电话码">
				</div>

				<div class="right">提交</div>

			</div> -->

			<div class="contactInfo">
				<div class="left">
					<img src="../assets/contactBg.png" class="contactBg" alt="" srcset="">
					<div class="rengong">
						<div class="key">
							<div class="key_title">电&nbsp;话&nbsp;服&nbsp;务</div>
							<div class="key_desc" v-html="phoneInfo">
<!--								(+022) 5966-5816 (总机电话)<br />-->
<!--								自助服务时间 :7*24 小时全天服务<br />-->
<!--								人工服务时间 :周一-周五 09 : 00-18 :00-->

							</div>
						</div>
						<div class="val">
							<img src="../assets/rengongBG.png" class="rengongBG" alt="" srcset="">
						</div>
					</div>
				</div>
				<div class="right">
					<img src="../assets/contactEmail.png" class="contactEmail" alt="">
					<div class="right_title">电子邮箱</div>
					<div class="right_desc" v-html="mailInfo">
<!--						请随时给我们发送电子邮件，我们会在 48 小时之内尽快给你答复。<br />在邮件内提供你的姓名、联系方式 (电话)和所在城市，售后问题请同时提供产品照片。<br />希丁联络邮箱: hilding.china@hildinganders.com-->
					</div>
				</div>
			</div>
			<f-foot />
		</div>
		<div class="mobile_base">
			<m-heard />
			<img src="../assets/mobile/contactBanner.png" class="banner" alt="" srcset="">
			<p class="m_title">联系我们</p>
			<p class="m_title_desc">CONTACT US</p>
			<!-- <p class="m_qr m60">请输入您的联系方式及疑问，我们将尽快<br />与您取得联络！</p> -->
			<!-- <input type="tel" placeholder="联系电话" class="tel"> -->

			<img src="../assets/mobile/contact1.png" class="contact1 m30" alt="" srcset="">
			<p class="m_qr m30" v-html="phoneInfo">
<!--				(+022) 5966-5816 (总机电话)<br /> 自助服务时间 :7*24 小时全天服务 <br />人工服务时间 :周一-周五 09 : 00-18 :00-->
			</p>
			<img src="../assets/mobile/contact2.png" class="contact2 m60" alt="" srcset="">
			<p class="m_qr m30" v-html="mailInfo">
<!--				请随时给我们发送电子邮件，我们会在 48 小时之内尽快给你答复。<br />在邮件内提供你的姓名、联系方式 (电话)和所在城市，售后问题请同时提供产品照片。 <br />希丁联络邮箱: hilding.china@hildinganders.com-->
			</p>
			<p class="h93"></p>
			<m-foot />
		</div>
	</div>
</template>

<script>
	import FHeard from '@/components/FHeard'
	import FFoot from '@/components/FFoot'
	import MHeard from '@/components/MHeard'
	import MFoot from '@/components/MFoot'
	import {contact_us} from "@/api";
	export default {
		name: 'philosophy',
		components: {
			FHeard,
			FFoot,
			MHeard,
			MFoot,
		},
		data() {
			return {
				phoneInfo: '',
				mailInfo: ''
			}
		},
		mounted() {
			this.getContactUs()
		},
		methods:{
			getContactUs() {
				contact_us().then(res => {
					console.log(res)
					res.data.forEach(item => {
						if (item.title === 'phone') {
							this.phoneInfo = item.content
						}
						if (item.title === 'mail') {
							this.mailInfo = item.content
						}
					})
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../css/Contact.scss';
</style>
