<template>
	<div class="order">
		<div class="pc_base">
			<f-heard />
			<img @click="$router.push('/')" src="../assets/menu_logo.png" class="pageLogo" alt="">
			<img src="../assets/orderBanner.png" class="orderBanner" alt="" srcset="">
			<img src="../assets/yun4.png" class="yun" alt="" />
			<p class="fp">自希丁创立以来，我们始终是酒店及家具行业领先公司值得信赖的业务合作伙伴。凭借遍布全球的19+个<br />产品研发中心，我们竭力为您的业务量身定制解决方案。</p>
			<img src="../assets/up4.png" class="up" alt="" />
<!--			<img src="../assets/demo.png" class="demo" alt="" srcset="">-->
			<div class="address-pc">
				<div id="allmap" style="width: 891px;height: 707px"></div>
				<div class="address-pc-r">
					<img src="../assets/address-img.png" alt="">
					<div class="address-pc-nr">
						<div class="address-title">{{ shopInfo.name }}</div>
						<div class="address-item">
							<img src="../assets/address-icon.png" alt="">
							{{ shopInfo.address }}
						</div>
						<div class="address-item">
							<img src="../assets/phone-icon.png" alt="">
							{{ shopInfo.phone }}
						</div>
						<div class="address-item">
							<img src="../assets/em-icon.png" alt="">
							{{ shopInfo.mail }}
						</div>
					</div>
				</div>
			</div>
			<img src="../assets/up4.png" class="up m120" alt="" />
			<f-foot />
		</div>
		<div class="mobile_base">
			<m-heard />
			<img src="../assets/mobile/orderBanner.png" class="banner" alt="" srcset="">
			<p class="m_title">立即购买</p>
			<p class="m_title_desc">ORDER NOW</p>
			<p class="m_qr m60">单击地图或使用搜索字段找到离您最近的体验店，近距离感受希丁的产品吧！</p>
<!--			<img src="../assets/mobile/demo.png" class="demo" alt="" srcset="">-->
			<div class="mobile_map">
				<div id="mobile_allmap" style="width: 100%;height: 371px"></div>
				<div class="mobile_map_bottom">
					<div class="mobile_map_bottom_l">
						<img src="../assets/address-img.png" alt="">
					</div>
					<div class="mobile_map_bottom_r">
						<div class="mobile_map_title">{{ shopInfo.name }}</div>
						<div class="mobile_map_item">
							<img src="../assets/mobile/mobile-address-icon.png" alt="">
							{{ shopInfo.address }}
						</div>
						<div class="mobile_map_item">
							<img src="../assets/mobile/mobile-phone-icon.png" alt="">
							{{ shopInfo.phone }}
						</div>
						<div class="mobile_map_item">
							<img src="../assets/mobile/mobile-em-icon.png" alt="">
							{{ shopInfo.mail }}
						</div>
					</div>
				</div>
			</div>
			<m-foot />
		</div>
	</div>
</template>

<script>
	import FHeard from '@/components/FHeard'
	import FFoot from '@/components/FFoot'
	import MHeard from '@/components/MHeard'
	import MFoot from '@/components/MFoot'
	import { shop } from '@/api/index'
	export default {
		name: 'Order',
		components: {
			FHeard,
			FFoot,
			MHeard,
			MFoot
		},
		data() {
			return {
				shopInfo: {}
			}
		},
		mounted() {
			this.getShop()
		},
		methods: {
			getShop() {
				shop().then(res => {
					this.shopInfo = res.data
					console.log(res.data.coordinate)
					let Point = res.data.coordinate.split(',')
					this.$nextTick(()=> {
						var map = new BMapGL.Map("allmap");
						var point = new BMapGL.Point(Point[0], Point[1]);
						map.centerAndZoom(point, 12);
						var zoomCtrl = new BMapGL.ZoomControl();
						map.addControl(zoomCtrl);
						var marker = new BMapGL.Marker(point);  // 创建标注
						map.addOverlay(marker);              // 将标注添加到地图中
						var opts = {
							width : 200,     // 信息窗口宽度
							height: 100,     // 信息窗口高度
							title : res.data.title , // 信息窗口标题
						}
						var infoWindow = new BMapGL.InfoWindow(`地址：${res.data.address}`, opts);  // 创建信息窗口对象
						marker.addEventListener("click", function(){
							map.openInfoWindow(infoWindow, point); //开启信息窗口
						});
					})
					this.$nextTick(()=> {
						var map = new BMapGL.Map("mobile_allmap");
						var point = new BMapGL.Point(Point[0], Point[1]);
						map.centerAndZoom(point, 11);
						var zoomCtrl = new BMapGL.ZoomControl();
						map.addControl(zoomCtrl);
						var marker = new BMapGL.Marker(point);  // 创建标注
						map.addOverlay(marker);              // 将标注添加到地图中
						var opts = {
							width : 200,     // 信息窗口宽度
							height: 100,     // 信息窗口高度
							title : res.data.title , // 信息窗口标题
						}
						var infoWindow = new BMapGL.InfoWindow(`地址：${res.data.address}`, opts);  // 创建信息窗口对象
						marker.addEventListener("click", function(){
							map.openInfoWindow(infoWindow, point); //开启信息窗口
						});
					})
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../css/Order.scss';
</style>
