<template>
	<div class="foot">
		<img src="../assets/f-logo.png" class="f_logo" alt="" />
		<div class="f_menu">
			<p @click="go('/')" class="zy">回到主页</p>
			<div class="items">
				<p class="erji">
					<span>关于希丁</span>
					<img src="../assets/pres.png" class="pre" alt="" srcset="">
				</p>
				<div class="erjicaidan">
					<div @click="go('/story')" class="items itemss">
						<p><span>品牌故事</span></p>
					</div>
					<div @click="go('/history')" class="items itemss">
						<p><span>历史</span></p>
					</div>
					<div @click="go('/Philosophy')" class="items itemss">
						<p><span>好睡哲学</span></p>
					</div>
					<div @click="go('/news')" class="items itemss">
						<p><span>新闻</span></p>
					</div>
				</div>

			</div>


			<div @click="go('/product')" class="items">
				<p>产品中心</p>
			</div>
			<div @click="go('/sleep')" class="items">
				<p>好睡实验室</p>
			</div>
			<div @click="go('/join')" class="items">
				<p>合作加盟</p>
			</div>
			<div @click="go('/order')" class="items">
				<p>立即购买</p>
			</div>
			<div @click="go('/contact')" class="items">
				<p>联系我们</p>
			</div>
			<!-- <div class="items" style="display: flex;align-items: center;justify-content: space-between;width: 82px;margin-top: 26px;">
				<span><img src="../assets/menu_dx_h.png" style="width: 29px;height: 21px;" alt="" srcset=""></span>
				<span class="pi"><img @click="showSearch(true)" src="../assets/menu_serch_h.png" style="width: 22px;height: 22px;" alt="" srcset=""></span>
			</div> -->
		</div>

		<div class="f_from">
			<div class="f_from_title">联系我们</div>
			<div class="emaile">
				<input type="text" placeholder="Enter Email address">
				<div class="btn" @click="go('/contact')">发送</div>
			</div>
			<div class="icon">
				<img v-for="(item,index) in followList" @click="showQrcode(item)" :key="index" :src="item.icon" alt="">
<!--				<img src="../assets/mobile/icon2.png" alt="">-->
<!--				<img @click="showQrcode(true)" src="../assets/mobile/icon3.png" alt="">-->
<!--				<img src="../assets/mobile/icon4.png" alt="">-->
			</div>
			<p class="fp"></p>
			<!-- <p class="fp">Hilding Anders AB Neptunigatan 1, Posthusplatsen 211 20 Malmö, Sweden</p> -->
			<p class="fp m30">电话服务<br/>（+022）5966-5816（总机电话）<br />自助服务时间：7*24小时全天服务<br />人工服务时间：周一-周五 09：00-18：00</p>

			<p class="fp m30" @click="go('/contentPrivacy')">
				<span style="cursor: pointer;" class="ys">隐私条款</span>
				<br />ICP备案（津ICP备2023000955号）  <br />HILDING希丁安（天津）家具有限责任公司</p>
		</div>

		<div class="bottom">© Hilding Anders 2023</div>

		<img src="../assets/ffootbg.png" class="ffootbg" alt="" srcset="">


		<div class="qrcode" v-if="showQr" @click="hideQrcode(false)" :class="{a: showQrs}">
<!--			@click.stop="rt"-->
			<div class="qrcode_info" >
				<img @click="hideQrcode(false)" style="cursor: pointer;" src="../assets/mobile/close.png" class="close" alt="">
				<div class="qrcode_title">更多详情请扫码添加<br />{{ showQrInfo.tip }}</div>
				<img :src="showQrInfo.content" class="qrodeIcon" alt="" srcset="">
			</div>
		</div>
	</div>

</template>

<script>
	import {follow} from "@/api";

	export default {
		data(){
			return {
				showQr: false,
				showQrs: false,
				showQrInfo: {},
				followList: []
			}
		},
		mounted() {
			this.getFollow()
		},
		methods:{
			// 获取分享
			getFollow() {
				follow().then(res => {
					console.log(res)
					this.followList = res.data
				})
			},
			showQrcode(item){
				if (item.type === 'link') {
					window.location.href = item.content
				} else {
					console.log('点击')
					this.showQr = true;
					this.showQrInfo = item
						setTimeout(() => {
							this.showQrs = true;
						}, 10);
				}
				// if(flag){
				// 	this.showQr = true;
				// 	setTimeout(() => {
				// 		this.showQrs = true;
				// 	}, 10);
				// }else{
				// 	this.showQrs = false;
				// 	setTimeout(() => {
				// 		this.showQr = false;
				// 	}, 300);
				// }
			},
			hideQrcode() {
				this.showQrs = false;
					setTimeout(() => {
						this.showQr = false;
					}, 300);
			},
			go(url){
				this.$router.push(url)
			},
			showSearch(flag){
				// console.log(11);
				//this.$parent.serch = true;
				this.$store.state.search = flag;
			},
		}
	}
</script>

<style lang="scss" scoped>
	.foot{
		// height: 520px;
		position: relative;
		background: #eeefef;
		min-width: 1250px;
		padding: 88px 150px 100px 150px;
		box-sizing: border-box;
		display: flex;
		font-family: "AlibabaPuHuiTiM";
		.itemss{
			padding-left: 20px;
			line-height: 34px;
			span:hover{
				border-bottom: 1px solid #909090;
			}
		}
		.erji{
			display: flex !important;
			align-items: center;
			span{
				margin-right: 14px;
			}
			img{
				width: 8px;
				position: relative;
				// top: 2px;
			}
		}
		.qrcode{
			position: fixed;
			opacity: 0;
			left: 0;
			top: 0;
			z-index: 99999;
			width: 100vw;
			height: 100vh;
			background: rgba(0, 51, 95, .6);
			backdrop-filter: blur(6px);
			transition: all .3s;
			&.a{
				opacity: 1;
			}
			.qrcode_info{
				width: 654px;
				min-height: 704px;
				background: #FFFFFF;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				padding: 83px 0 20px 0;
				.close{
					position: absolute;
					top: 46px;
					right: 50px;
					width: 26px;
					height: 26px;
				}
				.qrcode_title{
					text-align: center;
					font-family: "AlibabaPuHuiTiM";
					color: #5E717A;
					line-height: 45px;
					letter-spacing: 6px;
					font-size: 24px;
				}
				.qrodeIcon{
					width: 430px;
					//height: 430px;
					margin: 10px auto;
				}
			}
		}

		.ffootbg{
			position: absolute;
			right: -346px;
			bottom: -87px;
			width: 915px;
			height: 478px;
			z-index: 1;

		}
		.bottom{
			position: absolute;
			left: 0;
			width: 100%;
			bottom: 0;
			height: 70px;
			font-size: 14px;
			color: #fff;
			background: #5096d0;
			display: flex;
			align-items: center;
			justify-content: center;
			z-index: 2;
		}
		.m30{
			margin-top: 30px;
		}
		.fp{
			// text-align: center;
			font-size: 14px;
			color: #6f7d84;
			line-height: 24px;
			text-align: left;
		}
		.icon{
			margin: 20px 0 20px 0;
			display: flex;
			align-items: center;
			img{
				flex: none;
				margin-right: 36px;
				cursor: pointer;
				width: 36px;
			}
		}
		.emaile{
			margin-top: 24px;
			display: flex;
			align-items: center;
			cursor: pointer;
			input{
				display: block;
				line-height: 64px;
				height: 64px;
				background: #fff;
				color: #a2a7a9;
				padding-left: 38px;
				box-sizing: border-box;
				width: 270px;
				font-size: 18px;
			}
			input::-webkit-input-placeholder{
			  color: #a2a7a9;
			}
			.btn{
				height: 64px;
				display: flex;
				align-items: center;
				justify-content: center;
				background:#5096d0;
				font-size: 18px;
				color: #ffffff;;
				width: 170px;
			}
		}
		.f_from{
			flex: none;
			position: relative;
			z-index: 2;
		}
		.f_from_title{
			font-size: 16px;
			color: #6f7d84;
		}
		.f_menu{

			.zy{
				font-size: 16px;
				color: #6f7d84;
				margin-bottom: 24px;
			}
			p{
				display: inline-block;
				cursor: pointer;
			}
			.items{
				line-height: 40px;
				font-size: 16px;
				color: #a2a7a9;

				.erjicaidan{
					display: none;
				}
				&:hover{
					.erjicaidan{
						display: block;
					}
				}
			}
		}
		.f_logo{
			width: 163px;
			flex: none;
			height: 40px;
			margin-right: 80px;
		}
		.f_menu{
			flex: 1;
			display: flex;
			flex-direction: column;
		}

		.ys:hover{
			text-decoration:underline;
		}
	}
</style>
