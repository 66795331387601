<template>
	<div class="sleep">
		<div class="pc_base">
			<f-heard />
			<img @click="$router.push('/')" src="../assets/menu_logo.png" class="pageLogo" alt="">
			<img src="../assets/sleepBanner.png" class="sleepBanner" alt="" srcset="">
			<img src="../assets/sleepBg.png" class="sleepBg" alt="" srcset="">
			<img src="../assets/yun3.png" class="yun" alt="" />
			<p class="fp">沙尼斯（Schänis）是一个美丽的瑞士小镇，位于列支敦士登和苏黎世之间。2014年，在这个风景如画的地方，我们创立了希丁的<span style="font-weight: bold;">好睡实验室</span>——借力科技与创新的力量，我们持续关注市场趋势并将创新带入人们的生活。我们期待通过产品的不断改进，实现我们用睡眠改善人们生活的使命。</p>
			<p class="fp m36">好睡实验室是一个令人惊叹的现代化测试中心，里面装配着先进的科技设备、奇形怪状的假人和各种测试站，操作员在其间运用多种方式检查评估床垫的多项性能。</p>
			<p class="fp m36">在这里，我们与独立的瑞士 AEH 研究所合作：对产品进行严格分析评估，经过无数次实验，最终在 Sleep-Lab 中获得认证。 </p>
			<p class="fp m36">“睡个好觉”四个字实现起来并不容易，仅仅是“定义测试类别需要哪些参数”便是一个长达数月的艰巨分析和研究过程。我们想探寻如何创建满足消费者个性化要求的科学测试，于是我们组织了由医学专家、物理治疗师、测试人员、客户和消费者共同参与的研讨会。</p>
			<p class="fp m36">在分析了现有研究方法和专业文献后，我们总结出以往的测试程序最多使用一至两个参数，但这是远远不够的。因此，根据多年累积的睡眠经验和适用的欧洲标准，我们基于<span style="font-weight: bold;">四个参数</span>开发了一种属于希丁的“睡眠测试”：人体工程学、卫生、床温和耐用性。</p>
			<img src="../assets/up3.png" class="up" alt="" />
			
			<div class="flipbook-viewport" @mouseover="strTimes(false)" @mouseout="strTimes(true)">
				<div class="container">
					<div class="base"></div>
					<div class="foot"></div>
					<div class="flipbooks" id="flipbooks">
						<div class="start"></div>
						<div class="left"></div>
						<div class="right"></div>
						<div class="left1"></div>
						<div class="right1"></div>
						<div class="left2"></div>
						<div class="right2"></div>
						<div class="left3"></div>
						<div class="right3"></div>
						<div class="left4"></div>
						<div class="right4"></div>
					</div>
				</div>
			</div>
			<p class="fp m100">人们把一生中三分之一的时间都花在我们制造的产品上，因此我们致力于保证所创造的产品质量，并认识到我们在业务中采取的每一个举动，都将对一个人或者一个家庭的健康和幸福产生影响。<span style="font-weight: bold;">我们坚决不走并抵制影响质量的捷径。</span></p>
			<p class="fp m36">
希丁的好睡实验室是我们分析、测试和认证产品品质的地方。从这里出发，我们坚持创造优质睡眠产品体验，给每一位消费者良好睡眠和幸福生活的保障。</p>
			<img src="../assets/sleepOld.png" class="old" alt="" />
			<f-foot />
		</div>
		<div class="mobile_base">
			<m-heard />
			<img src="../assets/mobile/sleepBanner.png" class="banner" alt="" srcset="">
			<p class="m_title">好睡实验室</p>
			<p class="m_title_desc">sleep lab</p>
			<p class="m_qr m60">沙尼斯（Schänis）是一个美丽的瑞士小镇，位于列支敦士登和苏黎世之间。2014年，在这个风景如画的地方，我们创立了希丁的<span style="font-weight: bold;">好睡实验室</span>——借力科技与创新的力量，我们持续关注市场趋势并将创新带入人们的生活。我们期待通过产品的不断改进，实现我们用睡眠改善人们生活的使命。</p> 
			<p class="m_qr m30">好睡实验室是一个令人惊叹的现代化测试中心，里面装配着先进的科技设备、奇形怪状的假人和各种测试站，操作员在其间运用多种方式检查评估床垫的多项性能。 </p>
			<p class="m_qr m30">在这里，我们与独立的瑞士 AEH 研究所合作：对产品进行严格分析评估，经过无数次实验，最终在 Sleep-Lab 中获得认证。 </p>
			<p class="m_qr m30">“睡个好觉”四个字实现起来并不容易，仅仅是“定义测试类别需要哪些参数”便是一个长达数月的艰巨分析和研究过程。我们想探寻如何创建满足消费者个性化要求的科学测试，于是我们组织了由医学专家、物理治疗师、测试人员、客户和消费者共同参与的研讨会。</p>
			<p class="m_qr m30">在分析了现有研究方法和专业文献后，我们总结出以往的测试程序最多使用一至两个参数，但这是远远不够的。因此，根据多年累积的睡眠经验和适用的欧洲标准，我们基于<span style="font-weight: bold;">四个参数</span>开发了一种属于希丁的“睡眠测试”：人体工程学、卫生、床温和耐用性。</p>
			<img v-if="!load" @click="load = true;" src="../assets/mobile/add.png" class="m_add" alt="" srcset="">
			<div class="swiper">
				<div class="swiper-container">
					<swiper :options="swiperOptions">
						<swiper-slide>
							<div class="container">
								<img class="swiperSleepBg" src="../assets/mobile/sleep1.png" alt="" srcset="">
								<div class="swiper-bottom swiper-bottom1">
									<img src="../assets/mobile/sleepIcon.png" class="sleepIcon" alt="">
								<p>放松的身体是确保健康睡眠的最重要因素之一。<br /> 因此，每个床垫都须要完美贴合人体曲线。为了获得最佳舒适度，我们测试躺卧、身体支撑和脊椎各个部位的舒适度。<br />衡量标准：舒适和放松。</p>
								</div>
							</div>
						</swiper-slide>
						
						<swiper-slide>
							<div class="container">
								<img class="swiperSleepBg" src="../assets/mobile/sleep2.png" alt="" srcset="">
								<div class="swiper-bottom swiper-bottom2">
									<img src="../assets/mobile/sleepIcon1.png" class="sleepIcon" alt="">
								<p>对患有过敏症状的人尤为重要。我们测试抗菌物质以防止细菌、真菌和异味。<br />衡量标准：清洁度和新鲜度。</p>
								</div>
							</div>
						</swiper-slide>
						
						<swiper-slide>
							<div class="container">
								<img class="swiperSleepBg" src="../assets/mobile/sleep3.png" alt="" srcset="">
								<div class="swiper-bottom swiper-bottom3">
									<img src="../assets/mobile/sleepIcon2.png" class="sleepIcon" alt="">
								<p>一张好床垫使用期限应长达10 年，并且能够承受水分、压力和温度的波动。我们测试寿命和耐用性。<br />衡量标准：可靠性和安全性。</p>
								</div>
							</div>
						</swiper-slide>
						
						<swiper-slide>
							<div class="container">
								<img class="swiperSleepBg" src="../assets/mobile/sleep4.png" alt="" srcset="">
								<div class="swiper-bottom swiper-bottom4">
									<img src="../assets/mobile/sleepIcon3.png" class="sleepIcon" alt="">
								<p>为了睡得好，我们需要在床上保持合适的温度和干燥的环境。 我们测试透气性、水分传输和温度控制。 <br />测量标准：干燥宜人的床体温度。</p>
								</div>
							</div>
						</swiper-slide>
					</swiper>
					<div class="swiper-pagination1" slot="pagination"></div>
				</div>
			</div>
			<!-- <div class="swiper" v-show="load">
				<div class="swiper-container">
					<swiper :options="swiperOptions">
						<swiper-slide>
							<div class="container">
								<img src="../assets/book6.png" alt="" srcset="">
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="container">
								<img src="../assets/book8.png" alt="" srcset="">
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="container">
								<img src="../assets/book10.png" alt="" srcset="">
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="container">
								<img src="../assets/book12.png" alt="" srcset="">
							</div>
						</swiper-slide>
					</swiper>
				</div>
				<div class="swiper-bottom">
					<div class="swiper-pagination1" slot="pagination"></div>
					<div class="swiper-bottom-desc">
						<img src="../assets/mobile/sleepIcon.png"class="sleepIcon" alt="">
						放松的身体是确保健康睡眠的最重要因素之一
					<br />
					放松的身体是确保健康睡眠的最重要因素之一。 因此，每个床垫都须要完美贴合人体曲线。为了获得最佳舒适度，我们测试躺卧、身体支撑和脊椎各个部位的舒适度。<br />衡量标准：舒适和放松。
					</div>
				</div>
			</div> -->
			<p v-show="load" class="m_qr">人们把一生中三分之一的时间都花在我们制造的产品上，因此我们致力于保证所创造的产品质量，并认识到我们在业务中采取的每一个举动，都将对一个人或者一个家庭的健康和幸福产生影响。<span style="font-weight: bold;">我们坚决不走并抵制影响质量的捷径。</span> </p>
			<p v-show="load" class="m_qr m30">希丁的好睡实验室是我们分析、测试和认证产品品质的地方。从这里出发，我们坚持创造优质睡眠产品体验，给每一位消费者良好睡眠和幸福生活的保障。</p>
			<img v-show="load" src="../assets/mobile/sleepBgs.png" class="sleepBgs" alt="">
			<m-foot />
		</div>
	</div>
</template>

<script>
	let timers = null;
	import $ from 'jquery'
	import turn from '../../utils/turn.js'
	import FHeard from '@/components/FHeard'
	import FFoot from '@/components/FFoot'
	import MHeard from '@/components/MHeard'
	import MFoot from '@/components/MFoot'
	import "swiper/css/swiper.css"
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	export default{
		name: 'sleep',
		components: {
			FHeard,
			FFoot,
			MHeard,
			MFoot,
			swiper,
			swiperSlide
		},
		data() {
			return {
				load: true,
				swiperOptions: {
					loop: true, //无限循环
					autoplay: {
						//自动切换
						delay: 5000,
						stopOnLastSlide: false, //切换到最后一个slide时停止自动切换
						disableOnInteraction: false, //用户操作swiper之后，是否禁止自动切换。默认为true
					},
					pagination: {
						el: ".swiper-pagination1", //若改变分页样式，需改变样式名字，默认为.swiper-pagination
						type: "bullets",
						clickable: true, //允许分页点击跳转
					},
				}
			}
		},
		beforeDestroy(){
			clearInterval(timers);
		},
		mounted() {
			// Create the flipbook
			clearInterval(timers);
			timers = setInterval(()=>{
				if(!$('.flipbooks')){
					clearInterval(timers)
					return;
				}
				$('.flipbooks').turn('next');
			},3000)
			$('.flipbooks').turn({
				// Width
				width: 1200,
				// Height
				height: 580,
				// Elevation
				elevation: 50,
				// Enable gradients
				gradients: true,
				// Auto center this flipbook
				autoCenter: true,
				page: 2,
				when: {
					turning: function(event, page) {
						if (page === 1) {
							window.history.go(0)
						}
						page === 1 && event.preventDefault(); // 禁止第1页时翻页
						page === 12 && event.preventDefault();
					},
					turned: function() {
						
					},
				},
			})
			
			// this.showMask(true);
		},
		methods:{
			strTimes(flag){
				if(flag){
					timers = setInterval(()=>{
						$('.flipbooks').turn('next');
					},3000)
				}else{
					clearInterval(timers);
				}
			}
		}
	}
</script>

<style lang="scss">
	@import '../css/Sleep.scss';
</style>