<template>
	<div class="story">
		<div class="pc_base">
			<f-heard />
			<img @click="$router.push('/')" src="../assets/menu_logo.png" class="pageLogo" alt="">
			<img src="../assets/storyBanner.png" class="storyBanner" alt="" srcset="">
			<img src="../assets/yun.png" class="yun" alt="" />
			<p class="fp">你好呀，我们是来自北欧瑞典的睡眠家居品牌：希丁HILDING。</p>
			<p class="fp m44">1939年，在瑞典一座小镇的木工车间里，我们的创始人<span style="font-weight: bold;">希丁·安德森</span>（Hilding Anderson）开设了他的第一间家具厂。之后他<span style="">慢慢</span>专注于床及睡眠产品领域的研发，<br />使希丁安（Hilding Anders Group）逐渐成为瑞典家喻户晓的名字，现发展成为全球最大的睡眠产品供应商之一。</p>
			<img src="../assets/up.png" class="up" alt="" />
			<div class="storys">
				<img src="../assets/story1.png" alt="" srcset="">
				<img src="../assets/story2.png" alt="" srcset="">
				<img src="../assets/story3.png" alt="" srcset="">
			</div>
			<p class="fp m70">80余年来，我们始终致力于为全球用户定制好睡产品。希丁HILDING——作为<span style="font-weight: bold;">希丁安集团旗下创始品牌</span>——今天我们来到中国，<br />期待为中国消费者带来科学健康、和充满北欧美学的睡眠体验。</p>
			<p class="fp m44">如同每一位瑞典人所相信的，希丁HILDING秉承“<span style="font-weight: bold;">自然、纯净、友好、清新</span>”的生活哲学，铭记使命：<span style="font-weight: bold;">通过睡眠改善人们的生活。</span></p>
			<img src="../assets/up.png" class="up" alt="" />
			<p class="fp m44">如集团宗旨所述：从独特创意到科学研发，从欧洲到亚洲，<span style="font-weight: bold;">我们自豪于提供满足所有人需求的寝具和睡眠解决方案</span>——因为我们有<br />“以人为本”的竞争优势。</p>
			<p class="fp m44">希丁安在全球拥有5000多名同事：我们来自于不同的国家，拥有不同的经历；但是我们共同努力成为全球寝具的领导者。我们运用对舒适睡眠的了解和研究, 以及对追求幸福的共同热情, <span style="font-weight: bold;">持续关注家庭中每一位成员的睡眠健康。</span></p>
			<img src="../assets/up.png" class="up" alt="" />

			<img src="../assets/old.png" class="old" alt="" />
			<p class="fp"><span style="font-weight: bold;">床是家庭生活的核心</span>；是家人们相处的温暖港湾。我们希望通过严选材质、精控品质，给家人安全而舒适、充满关爱与归属感的睡眠环境。</p>
			<p class="fp m44">我们坚信，良好睡眠引领幸福生活。现在我们来到中国，希望携手更多中国家庭，共同关注科学睡眠。</p>
			<p class="fp m44"><span style="font-weight: bold;">跟瑞典希丁一起，安心睡个好觉。</span></p>
			<img src="../assets/dian.png" class="dian" alt="" />
			<f-foot />
		</div>
		<div class="mobile_base">
			<m-heard />
			<img src="../assets/mobile/storyBanner.png" class="banner" alt="" srcset="">
			<p class="m_title">品牌故事</p>
			<p class="m_title_desc">Brand Story</p>
			<p class="m_qr m60">你好呀，我们是来自北欧瑞典的睡眠家居品牌：希丁HILDING。</p>
			<p class="m_qr m30">1939年，在瑞典一座小镇的木工车间里，我们的创始人<span style="font-weight: bold;">希丁·安德森</span>（Hilding Anderson）开设了他的第一间家具厂。之后他<span style="">慢慢</span>专注于床及睡眠产品领域的研发，使希丁安（Hilding Anders Group）逐渐成为瑞典家喻户晓的名字，现发展成为全球最大的睡眠产品供应商之一。</p>
			<img src="../assets/mobile/add.png" @click="load = true;" v-if="!load" class="m_add" alt="" srcset="">
			<div v-show="load" class="swiper">
				<div class="swiper-container">
					<swiper :options="swiperOptions">
						<swiper-slide>
							<div class="container">
								<img src="../assets/story1.png" alt="" srcset="">
								<p class="name">创始人：希丁</p>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="container">
								<img src="../assets/story2.png" alt="" srcset="">
								<p class="name">创始人：希丁</p>
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="container">
								<img src="../assets/story3.png" alt="" srcset="">
								<p class="name">创始人：希丁</p>
							</div>
						</swiper-slide>
					</swiper>
				</div>
				<div class="swiper-bottom">
					<div class="swiper-pagination1" slot="pagination"></div>
				</div>
			</div>
			<p v-show="load" class="m_qr m100">
				80余年来，我们始终致力于为全球用户定制好睡产品。希丁HILDING——作为<span style="font-weight: bold;">希丁安集团旗下创始品牌</span>——今天我们来到中国，期待为中国消费者带来科学健康、和充满北欧美学的睡眠体验。
			</p>
			<p v-show="load" class="m_qr m30">如同每一位瑞典人所相信的，希丁HILDING秉承“<span style="font-weight: bold;">自然、纯净、友好、清新</span>”的生活哲学，铭记使命：<span style="font-weight: bold;">通过睡眠改善人们的生活。</span></p>
			<img v-show="load" src="../assets/mobile/storys.png" class="storys" alt="" srcset="">
			<p v-show="load" class="m_qr m30">如集团宗旨所述：从独特创意到科学研发，从欧洲到亚洲，<span style="font-weight: bold;">我们自豪于提供满足所有人需求的寝具和睡眠解决方案</span>——因为我们有“以人为本”的竞争优势。</span></p>
			<p v-show="load" class="m_qr m30">希丁安在全球拥有5000多名同事：我们来自于不同的国家，拥有不同的经历；但是我们共同努力成为全球寝具的领导者。我们运用对舒适睡眠的了解和研究, 以及对追求幸福的共同热情, <span style="font-weight: bold;">持续关注家庭中每一位成员的睡眠健康。</span></p>
			
			<p v-show="load" class="m_qr"><span style="font-weight: bold;">床是家庭生活的核心</span>；是家人们相处的温暖港湾。我们希望通过严选材质、精控品质，给家人安全而舒适、充满关爱与归属感的睡眠环境。</p>
			<p v-show="load" class="m_qr m30">我们坚信，良好睡眠引领幸福生活。现在我们来到中国，希望携手更多中国家庭，共同关注科学睡眠。</p>
			<p v-show="load" class="m_qr m30"><span style="font-weight: bold;">跟瑞典希丁一起，安心睡个好觉。</span></p>
			<p v-show="load" class="h100"></p>
			<m-foot />
		</div>
	</div>
</template>

<script>
	import FHeard from '@/components/FHeard'
	import FFoot from '@/components/FFoot'
	import MHeard from '@/components/MHeard'
	import MFoot from '@/components/MFoot'
	import "swiper/css/swiper.css"
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	export default {
		name: 'Story',
		components: {
			FHeard,
			FFoot,
			MHeard,
			MFoot,
			swiper,
			swiperSlide
		},
		data() {
			return {
				load: true,
				swiperOptions: {
					loop: true, //无限循环
					autoplay: {
						//自动切换
						delay: 5000,
						stopOnLastSlide: false, //切换到最后一个slide时停止自动切换
						disableOnInteraction: false, //用户操作swiper之后，是否禁止自动切换。默认为true
					},
					pagination: {
						el: ".swiper-pagination1", //若改变分页样式，需改变样式名字，默认为.swiper-pagination
						type: "bullets",
						clickable: true, //允许分页点击跳转
					},
				}
			}
		}
	}
</script>

<style lang="scss">
	@import '../css/Story.scss';
</style>
