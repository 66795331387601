<template>
	<div>
		<div class="pc_base">
			<!-- <f-heard /> -->
			<div style="background: #5096d0;height: 70px;display: flex;align-items: center;">
				<img @click="$router.push('/')" src="../assets/menu_logo.png" style="left: 4%;position: relative;cursor: pointer;" class="pageLogo" alt="">
			</div>
		</div>
		<div class="mobile_base">
			<m-heard />
		</div>
		<div class="content-privacy">

			<h1 style="text-align: center;padding-bottom: 30px;">用户隐私政策</h1>
			<p>本隐私声明阐述了 HILDING希丁安（天津）家具有限责任公司（以下统称“HILDING”、“我们”或“我方”）如何通过HILDING网站，包括但不限于 HILDING.cn
				及其子域（每一单称“网站”）以及通过提供用于计算机和移动设备或基于计算机或移动设备进行使用的
				HILDING服务、产品和软件（在本隐私声明中，统称“产品”）收集、使用以及共享信息。请记住，您对我们的网站和产品的使用须始终遵守包含本隐私声明的适用条款和条件。</p>


			<p class="f">个人信息</p>
			<p>“<span style="font-weight: bold;">个人信息</span>”是将您识别为个人或与可识别个人相关的信息。我们通过网站和产品收集信息，例如：</p>
			<ul>
				<li>姓名</li>
				<li>网站用户名</li>
				<li>邮寄地址</li>
				<li>电话号码</li>
				<li>电子邮件地址</li>
				<li>资料图片</li>
				<li>社交媒体帐户 ID</li>
				<li>IP 地址</li>
				<li>您指定的帐户设置</li>
			</ul>
			<p>我们收集和处理这些类型的个人信息，以便为您提供我们的服务以及遵从法律要求。如果您未提供我们要求的信息，我们可能无法为您提供本网站以及产品的完整体验，或是可能无法遵守适用的法律义务。</p>


			<p class="f">个人信息的收集</p>

			<p>我们通过各种方式收集个人信息，包括：</p>
			<ul>
				<li>通过网站及产品。
					
				<li style="margin-left: 28px;">&我们会在以下情况下通过我们的网站收集个人信息，例如，当您注册帐户、回复表格、调查、抽奖、竞赛或申请，以及通过使用各种产品或参与计划或服务收集。</li>

				<li>通过您提供的信息。</li>
				<li style="margin-left: 28px;">当您联系客户服务、注册或购买我们的产品时，我们可能会收集个人联系信息（例如您的姓名、电子邮件地址和电话号码）。</li>
				<li style="margin-left: 28px;">我们也可能会收集有关产品退回、保修和产品/消费者注册的个人信息。</li>

				<li>来自其他来源。</li>
				<li style="margin-left: 28px;">
					当其他来源与我们分享信息时，我们会从这些来源收到您的个人信息，例如公开数据库和联合营销合作伙伴。我们可能允许您与第三方社交媒体网站分享信息，或使用社交媒体网站或其他网站以创建或连接至您的帐户。这些社交媒体网站可能会允许我们访问来自您与在线服务的互动而由其存储的某些个人信息（例如，所查看的内容和您已看到或点击的内容中关于广告的信息等）。您可通过第三方网站上的隐私设置和您授予我们权限时的许可权控制您允许我们访问的内容。
				</li>
			</ul>
			<p>请注意，如果您在与本网站或产品有关的情况下向我们或我们的服务提供商披露其他人的任何个人信息，则您表示您有权这样做并允许我们按照本隐私声明使用这些信息。</p>


			<p class="f">个人信息的使用</p>

			<p>我们将个人信息用于以下目的：</p>
			<ul>
				<li>提供网站和产品的功能以及满足您的请求。</li>
				<li style="margin-left: 28px;">答复您的问题、满足您的请求并为您提供相关支持。</li>
				<li style="margin-left: 28px;">向您发送管理信息，如我们的条款、条件和政策变更。</li>
				<li style="margin-left: 28px;">为您的体验确定适当的语言。</li>

				<li>我们通过这些活动来管理我们与您的合同关系并/或遵守法律义务。</li>

				<li>为您提供营销材料并个性化您在网站上以及对我们产品的体验。</li>
				<li style="margin-left: 28px;">在您的同意下发送我们认为您可能感兴趣的营销通讯。</li>
				<li style="margin-left: 28px;">通过展示为您量身定制的产品和优惠个性化您的体验，并支持社交分享功能。</li>

				<li>我们会在经过您同意的情况下或为了我们合法的商业利益开展这些活动。</li>

				<li>允许您参加抽奖和其他促销活动。</li>
				<li style="margin-left: 28px;">允许您参加抽奖、比赛和类似的促销活动，以及管理这些活动。部分活动有附加规则，其中包含有关我们如何使用和披露您的个人信息的信息。</li>

				<li>我们在经过您同意的情况下或为了管理我们与您的合同关系开展这些活动。</li>

				<li>实现我们的商业目标和分析信息。</li>
				<li style="margin-left: 28px;">汇总和/或匿名处理个人信息。</li>
				<li style="margin-left: 28px;">出于我们的商业目的，例如数据分析、审计、欺诈监控和预防，开发新产品，加强、改进或修改我们的网站和产品，确定使用趋势，确定我们促销活动的有效性以及运营和扩展我们的业务活动。</li>
				<li style="margin-left: 28px;">将 IP 地址用于计算使用量、诊断服务器问题和管理网站以及产品等目的。我们也可能从您的 IP 地址推导出您的大概位置。</li>

				<li>我们开展这些活动以管理我们与您的合同关系、遵守法律义务和/或基于我们合法的商业利益。</li>

			</ul>
			<p class="f">个人信息的披露</p>

			<p>我们会披露个人信息给以下组织：</p>
			<ul>
				<li style="margin-left: 28px;">披露给我们的附属公司，包括：</li>
				<li style="margin-left: 42px;">HILDING希丁安（天津）家具有限责任公司</li>
				<li style="margin-left: 28px;">以用于本隐私声明中所述的目的。</li>

				<li style="margin-left: 28px;">披露给我们的第三方服务提供商，其负责提供网站托管、数据分析、信息技术和相关基础设施供应、客户服务、电子邮件传送、审计等服务。</li>

				<li style="margin-left: 28px;">披露给抽奖、比赛和类似促销的第三方赞助商。未经您的同意，我们不会出于非附属第三方自己的直接营销目的与其分享个人信息。</li>

			</ul>
			<p class="f">其他用途和披露</p>

			<p>我们也会在认为出于以下目的有必要或适当时使用和披露您的个人信息：</p>
			<ul>
				<li>遵守适用的法律和法规。</li>
				<li style="margin-left: 28px;">这可能包括您居住国家/地区以外的法律和法规。</li>

				<li>应公共和政府部门的要求或与执法部门合作。</li>
				<li style="margin-left: 28px;">这可能包括您居住国家/地区以外的相关部门。</li>

				<li>出于其他法律原因。</li>
				<li style="margin-left: 28px;">例如，为了执行我们的服务条款或和您之间的其他协议；以及</li>
				<li style="margin-left: 28px;">为了保护我们或我们的附属公司、您或其他人的权利、隐私、安全或财产。</li>

				<li>与销售或商业交易相关。</li>
				<li style="margin-left: 28px;">
					如果我们的业务、资产或股票的全部或任何部分接受任何重组、合并、出售、合资、分配、转让或其他处置（包括与任何破产或类似诉讼有关），我们可针对第三方使用您的信息，或向其披露或转让您的信息。这些第三方可能包括收购实体及其顾问等。
				</li>

			</ul>
			<p class="f">其他信息</p>

			<p>“其他信息”是指任何未透露您的具体身份或与可识别个体无直接关系的信息。</p>
			<ul>
				<li style="margin-left: 28px;">浏览器和设备信息</li>
				<li style="margin-left: 28px;">通过 Cookie、像素标签和其他技术收集的信息</li>
				<li style="margin-left: 28px;">通过使用我们的设备和其他产品（如我们的移动应用程序）收集的信息</li>
				<li style="margin-left: 28px;">您提供的没有显示您的具体身份的人口统计信息和其他信息</li>
				<li style="margin-left: 28px;">已经过汇总不再显示您的具体身份的信息</li>
			</ul>
			<p>如果适用法律要求我们将其他信息视为个人信息，则我们可能会根据本政策中详细说明的目的使用和披露个人信息。</p>


			<p class="f">其他信息的收集</p>

			<p>我们和我们的服务提供商通过各种方式收集其他信息，其中包括：</p>
			<ul>
				<li>通过您的浏览器或设备。</li>
				<li style="margin-left: 28px;">某些信息是由大部分浏览器或通过设备自动收集，如媒体访问控制(MAC) 地址、计算机类型（Windows 或 Mac）、屏幕分辨率、操作系统名称和版本、设备制造商和型号、语言、Internet
					浏览器类型和版本，以及您正在使用的网站或产品（例如移动应用程序）的名称和版本。我们使用这些信息来确保网站和产品的正常运行。</li>

				<li>通过使用我们的设备和其他产品。</li>
				<li style="margin-left: 28px;">
					对于我们的一些消费型产品，我们可能会收集产品性能信息以及有关您如何使用产品的一般统计信息。我们只会在您使用产品并同意我们的条款和条件、最终用户许可协议以及本隐私声明的情况下收集此类信息。我们还可能会收集有关您文件的综合元数据。我们将使用这些综合数据来增强我们的产品和服务，并改善与这些产品相关的一般营销活动。我们会对数据进行汇总，用来为我们的现有或潜在业务合作伙伴、赞助商、广告商或其他第三方提供有关本网站或我们产品的使用说明，或者回应政府要求。
				</li>

				<li>使用 Cookie 和类似技术。</li>
				<li style="margin-left: 28px;">我们会通过您的浏览器或设备，以及您对网站和产品的使用，使用Cookie 和类似技术自动收集信息。请参阅我们的Cookie 声明了解有关这些做法的更多信息。</li>

				<li>百度统计。</li>
				<li style="margin-left: 28px;">
					我们使用的百度统计会使用cookie和类似技术收集和分析关于服务使用的信息，并提供活动和趋势报告。如果您是我们网站或产品的注册用户，我们可能会将本信息与您可能提供的其他信息一起使用，从而改进计划或网站的服务和其他产品。本服务可能还会收集有关使用其他网站、应用和网上资源的信息。您可以访问以下网址，了解
					百度统计 做法的更多信息：https://tongji.baidu.com/web/help/article?id=330。</li>

				<li>IP 地址。</li>
				<li style="margin-left: 28px;">您的 IP 地址将由您的 Internet
					服务提供商自动分配给您的计算机。每当用户访问网站和/或产品时，系统就可能会自动识别IP地址以及访问时间和访问的页面，并将其记录在我们的服务器日志文件中。收集 IP
					地址是标准做法，众多网站、应用程序和其他服务均会自动完成收集。我们会将 IP 地址用于计算使用量、诊断服务器问题和管理网站和产品等目的。我们也可能从您的IP 地址推导出您的大概位置。</li>

			</ul>
			<p class="f">其他信息的使用和披露</p>

			<p>在某些情况下，我们会组合其他信息与个人信息。如果组合，我们将组合后的信息视为个人信息。</p>


			<p class="f">广告</p>

			<p>HILDING会在我们的网站和产品页面以及其他非HILDING所属网站上做广告。我们与广告服务供应商签约，他们会通过使用 Cookie 和其他类似技术向您提供广告。</p>


			<p class="f">禁止跟踪</p>

			<p>当前，我们的产品以及网站不支持“禁止跟踪”请求。</p>


			<p class="f">第三方</p>

			<p>本隐私声明并未涉及任何第三方（包括任何运营本网站或产品所链接的任何网站或服务的第三方）的隐私、信息或其他实践，我们也对其隐私、信息或其他实践概不负责。在网站或产品上包含链接并不意味着我们或我们的附属公司对链接网站或服务的认可。此外，我们不对任何独立第三方社交媒体平台提供商、应用程序开发商、应用程序提供商、操作系统提供商、无线服务提供商、设备制造商或其他组织的信息收集、使用、披露或安全政策或惯例负责，包括您通过本网站或产品或者就本网站或产品披露给其他组织的任何个人信息，例如，使用通过本网站或产品链接或集成的第三方消息服务作出的披露。
			</p>


			<p class="f">安全性</p>

			<p>我们制定了合理的安全措施，以防止我们控制的信息丢失、误用和更改。然而，请注意，尽管我们努力保护您的个人信息，但我们仍然不能保证或确保您以在线方式向我们发布或传达的任何信息的安全，并且我们对您个人信息的失窃、破坏或无意泄露不负有责任。如果您有理由相信您与我们的互动不再安全，请立即通过下文“联系我们”部分的方式通知我们。
			</p>


			<p class="f">选择和访问</p>

			<p>您对我们使用和披露您的个人信息的选择</p>

			<p>您可以选择停止：</p>
			<ul>
				<li>
					如果您不再希望收到我们的营销相关电子邮件，您可以通过下文“联系我们”部分的方式通知我们或单击营销电子邮件中的“取消订阅”链接选择退出。我们会在合理可行的范围内尽快满足您的要求。请注意，如果您选择不接收来自我们的营销相关电子邮件，我们仍可能会向您发送重要的管理消息。
				</li>
			</ul>

			<p class="f">如何行使您的权利</p>

			<p>如果您想请求访问、更正、更新、禁止、限制或删除您的个人信息，或者想要接收您之前提供给我们的个人信息的电子副本，以便将其传输至另一家公司（在适用法律向您提供数据迁移权的情况下），或者如果您希望撤回对于我们收集、使用和披露您的个人信息的同意，禁止自动决策，或禁止处理您公开披露的信息，您可以通过下文“联系我们”部分的方式向我们发送您的请求。如果您的近亲离世，在适用法律规定的范围内，您可以请求访问、更正、更新、接收副本或删除其信息。
			</p>
			<p>我们会尽可能在三十 (30) 天内或在合理可行的情况下，根据适用的法律尽快满足您的要求。</p>
			<p>在您的请求中，请明确说明相关详情，例如您希望更改哪些个人信息，您是否希望从我们的数据库中删除您的个人信息，或者您希望如何限制我们对您个人信息的使用。请勿提供超出表单要求的更多个人信息。为了保护您，我们将在处理您的请求之前验证您的身份。当您近亲离世且您要求访问、更正、更新、接收副本或删除其信息时，我们可能需要验证您的身份以及您与其的关系。
			</p>
			<p>请注意，我们需要出于记录保存目的保留有关您请求的特定受限信息，从而完成您在请求更改或删除之前开始的任何交易，确定、行使或维护法定求偿权，以及遵守适用法律法规和/或其他法律规定。</p>


			<p class="f">保留期</p>

			<p>根据获得信息的目的，我们将在需要或允许的期限内保留您的个人信息。用于确定我们保留期限的标准包括：(i)
				我们与您保持关系并向您提供网站或产品的时间长度（例如，只要您有我们的帐户或继续使用网站或产品）；(ii)
				我们是否承担法律或商业义务（例如，某些法律要求我们在一段时间内保留您的交易记录，之后才能删除它们）；或 (iii) 鉴于我们的法律地位（例如适用的法定时效监管调查法或其他法律事项），保留是否可取。
			</p>


			<p class="f">未成年人使用网站和产品</p>

			<p>本网站和我们的产品并不针对在所居住国家未达到成年年龄的个人。我们不会故意收集18 岁以下人士的个人信息。</p>


			<p class="f">司法管辖权和跨境转移</p>

			<p>您的个人信息可能在我们拥有设施或与服务提供商互动的任何国家/地区进行存储和处理。请注意，当您使用本网站或产品时，我们会在正常的业务运营过程中将信息传输到您所居住国家/地区以外的国家/地区（包括美国）。这些国家/地区可能有与您所在国家/地区有不同的数据保护规则，或者可能被您所在国家/地区认为不够充分。当我们将您的个人信息转移到您所居住国家/地区之外时，我们会根据我们的法律义务采取适当的保护措施，以确保无论您的个人信息转移到哪个国家/地区，都将得到适当的保护。在某些情况下，这些其他国家/地区的法院、执法机构、监管机构或安全机构可能有权访问您的个人信息。
			</p>

			<p>有些国家/地区经欧盟委员会认可，提供符合欧洲经济区 (“EEA”)
				标准的适当数据保护水平。针对从欧洲经济区转移到欧盟委员会认为保护不够充分的国家/地区的数据，我们已经制定了适当的措施，例如欧盟委员会为保护您的个人信息而采用的标准合同条款。</p>

			<p>如果您是中国居民，我们仅在基于一项或多项法律依据（例如履行合同或法律义务）的情况下传输您的个人信息。针对从中国转移到其他国家或地区的数据，我们将采取中国国家互联网信息办公室采用的标准合同条款等措施。您还可以根据适用法律要求我们向您提供有关传输您的个人信息的信息。
			<p>您可以通过下文“联系我们”部分所述方式索要这些措施的副本。</p>


			<p class="f">敏感信息</p>

			<p>我们要求您不要向我们发送，也不要在本网站上或通过本网站或者以其他方式向我们披露任何敏感的个人信息（例如，社保号码，与种族或种族本源、政治见解、宗教或其他信仰（例如教义或哲学信仰）有关的信息，性行为，健康，残疾，生物特征或遗传特征，犯罪背景，个人金融账户数据，跟踪追踪数据或地理位置数据，14
				岁以下未成年人数据，或工会会员身份），或者任何其他可能以适用法律规定的相同方式影响您的信息。</p>


			<p class="f">争议解决</p>
			<ul>
				<li>
					本隐私政策的解释及争议解决均应适用中华人民共和国大陆地区法律。任何因本隐私政策以及我们处理您个人信息事宜引起的争议，双方应协商友好解决；若不能协商解决，您同意将争议提交至北京市西城区人民法院诉讼解决。
				</li>

				<li>本隐私政策的标题仅为方便及阅读而设，并不影响正文其中任何规定的含义或解释。</li>
			</ul>

			<p class="f">联系我们</p>

			<p>电话服务：（+022）5966-5816（总机电话）</p>
			<p>自助服务时间：7*24小时全天服务</p>
			<p>人工服务时间：周一-周五 09：00-18：00</p>

			<p>电子邮箱</p>
			<p>请随时给我们发送电子邮件，我们会在48小时之内尽快给你答复。</p>
			<p>希丁联络邮箱：hilding.china@hildinganders.com</p>
		</div>
		<div class="pc_base">
			<f-foot />
		</div>
		<div class="mobile_base">
			<m-foot />
		</div>
	</div>
</template>

<script>
	import FHeard from '@/components/FHeard'
	import FFoot from '@/components/FFoot'
	import MHeard from '@/components/MHeard'
	import MFoot from '@/components/MFoot'
	export default {
		name: 'philosophy',
		components: {
			FHeard,
			FFoot,
			MHeard,
			MFoot,
		},
	}
</script>

<style lang="scss" scoped>
	.content-privacy{
		font-size: 14px !important;
		max-width: 1500px;
		padding: 0 20px;
		margin: 40px auto 60px;
		
		li{
			list-style-type: disc;
			margin-left: 14px;
		}
		
		p{
			margin: 10px 0;
		}
		p.f{
			font-weight: bold;
		}
	}
</style>
