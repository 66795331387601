<template>
	<div class="philosophy">
		<div class="pc_base">
			<f-heard />
			<img @click="$router.push('/')" src="../assets/menu_logo.png" class="pageLogo" alt="">
			<img src="../assets/philosophyBanner.png" class="philosophyBanner" alt="" srcset="">
			<img src="../assets/yun2.png" class="yun" alt="" />
			<p class="fp">我们的一生中有三分之一的时间都在睡觉。 但“睡眠”究竟是什么？简单来讲，睡眠状态可以描述为一种长时间的休息，在此期间全身放松，人会对周围环境的感知逐渐消失或变得微弱。在睡眠过程中，你会对外界刺激（声、光等）的反应大大降低。</p>
			<p class="fp m36">睡眠发生在床上，但我们却对支撑我们三分之一生命的“床”知之甚少。它是由什么制成的？它如何能更好地支撑我的身体？选择怎样的床垫才能睡得更健康更舒心？</p>
			<p class="fp m36">在关注以上答案之前，我们需要将目光转移到个体的人身上。无论是对于单身或已婚的年轻人、有孩子的家庭、还是老年人，我们都需要关注个体的差异。每个人的睡眠习惯不同、对睡眠环境的感知也有所区别：比如有人能敏感察觉伴侣发出的轻微刺激（如翻身、呼吸），有人则能较为深沉地浸入梦乡。</p>
			<p class="fp m36">希丁HILDING希望通过睡眠改善人们的生活。从“<span style="font-weight: bold;">以人为本</span>”出发，我们推出的每一款产品都全力秉承<span style="font-weight: bold;">以消费者为中心</span>，并以<span style="font-weight: bold;">可持续性和舒适性</span>作为卓越工艺的主要关注点。</p>
			<p class="fp m36">拥有良好睡眠才更能品味幸福生活。睡个好觉，睡醒后去感受光线散落在阳台间的温度，呼吸自然中清新的空气，迈出新一天为生活奋斗的第一步。</p>
			<p class="fp m36">我们是希丁HILDING，我们来自瑞典；我们从心底相信，一家人无论身在何处，每一个人都值得拥有一夜好眠。<span style="font-weight: bold;">和瑞典希丁一起，是时候睡个好觉了。</span></p>
			<img src="../assets/up2.png" class="up" alt="" />
			<div class="philosophys">
				<img src="../assets/philosophys1.png" alt="" srcset="">
				<img src="../assets/philosophys2.png" alt="" srcset="">
			</div>
			<f-foot />
		</div>
		<div class="mobile_base">
			<m-heard />  
			<img src="../assets/mobile/philosophyBanner.png" class="banner" alt="" srcset="">
			<p class="m_title">好睡哲学</p>
			<p class="m_title_desc">The Good Sleep Philosophy</p>
			<p class="m_qr m60">我们的一生中有三分之一的时间都在睡觉。 但“睡眠”究竟是什么？简单来讲，睡眠状态可以描述为一种长时间的休息，在此期间全身放松，人会对周围环境的感知逐渐消失或变得微弱。在睡眠过程中，你会对外界刺激（声、光等）的反应大大降低。</p>
			<div class="swiper">
				<div class="swiper-container">
					<swiper :options="swiperOptions">
						<swiper-slide>
							<div class="container">
								<img src="../assets/philosophys1.png" alt="" srcset="">
							</div>
						</swiper-slide>
						<swiper-slide>
							<div class="container">
								<img src="../assets/philosophys2.png" alt="" srcset="">
							</div>
						</swiper-slide>
					</swiper>
				</div>
				<div class="swiper-bottom">
					<div class="swiper-pagination1" slot="pagination"></div>
					<div class="swiper-bottom-desc">睡眠发生在床上，但我们却对支撑我们三分之一生命的“床”知之甚少。它是由什么制成的？它如何能更好地支撑我的身体？选择怎样的床垫才能睡得更健康更舒心？</div>
				</div>
			</div>
			<p class="m_qr m100" >在关注以上答案之前，我们需要将目光转移到个体的人身上。无论是对于单身或已婚的年轻人、有孩子的家庭、还是老年人，我们都需要关注个体的差异。每个人的睡眠习惯不同、对睡眠环境的感知也有所区别：比如有人能敏感察觉伴侣发出的轻微刺激（如翻身、呼吸），有人则能较为深沉地浸入梦乡。</p>
			
			<p class="m_qr m60">希丁HILDING希望通过睡眠改善人们的生活。从“<span style="font-weight: bold;">以人为本</span>”出发，我们推出的每一款产品都全力秉承<span style="font-weight: bold;">以消费者为中心</span>，并以<span style="font-weight: bold;">可持续性和舒适性</span>作为卓越工艺的主要关注点。</p>
			<p class="m_qr m60">拥有良好睡眠才更能品味幸福生活。睡个好觉，睡醒后去感受光线散落在阳台间的温度，呼吸自然中清新的空气，迈出新一天为生活奋斗的第一步。</p>
			<p class="m_qr m60" style="margin-bottom: 1rem;">我们是希丁HILDING，我们来自瑞典；我们从心底相信，一家人无论身在何处，每一个人都值得拥有一夜好眠。<span style="font-weight: bold;">和瑞典希丁一起，是时候睡个好觉了。</span></p>
			<!-- <img src="../assets/mobile/add.png" class="m_add" alt="" srcset=""> -->
			<m-foot />
		</div>
	</div>
</template>

<script>
	import FHeard from '@/components/FHeard'
	import FFoot from '@/components/FFoot'
	import MHeard from '@/components/MHeard'
	import MFoot from '@/components/MFoot'
	import "swiper/css/swiper.css"
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper";
	export default {
		name: 'philosophy',
		components: {
			FHeard,
			FFoot,
			MHeard,
			MFoot,
			swiper,
			swiperSlide
		},
		data() {
			return {
				swiperOptions: {
					loop: true, //无限循环
					autoplay: {
						//自动切换
						delay: 5000,
						stopOnLastSlide: false, //切换到最后一个slide时停止自动切换
						disableOnInteraction: false, //用户操作swiper之后，是否禁止自动切换。默认为true
					},
					pagination: {
						el: ".swiper-pagination1", //若改变分页样式，需改变样式名字，默认为.swiper-pagination
						type: "bullets",
						clickable: true, //允许分页点击跳转
					},
				}
			}
		}
	}
</script>

<style lang="scss">
	@import '../css/Philosophy.scss';
</style>
