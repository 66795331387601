<template>
	<div class="join">
		<div class="pc_base">
			<f-heard />
			<img @click="$router.push('/')" src="../assets/menu_logo.png" class="pageLogo" alt="">
			<img src="../assets/joinBanner.png" class="joinBanner" alt="" srcset="">
			<img src="../assets/yun4.png" class="yun" alt="" />
			<p class="fp">自希丁创立以来，我们始终是酒店及家具行业领先公司值得信赖的业务合作伙伴。凭借遍布全球的19+个产品研发中心，我们竭力为您的业务量身定制解决方案。</p>
			<img src="../assets/up4.png" class="up" alt="" />
			<img src="../assets/joinLogos.png" class="pjoinLogos" alt="" srcset="">
			<div class="joinTipsPs">希丁安Hilding Anders全球合作伙伴</div>
			<!-- <img src="../assets/mobile/joinTips.png" class="joinTipsP" alt="" srcset=""> -->
			<div class="b_tips" style="margin: 130px 0 40px 0;">大中华区联络人：Ronald Tong 童戎戎 <br />Email:
				Ronald.tong@hildinganders.com <br />Mobile: (+86) 186-0096-5686</div>
			<img src="../assets/yun4.png" style="margin-bottom: 100px;margin-top: 0;" class="yun" alt="" />
			<div class="f_from">
				<div class="items">
					<div class="item">
						<div class="item_name">姓名</div>
						<div class="inp">
							<input type="text" placeholder="请输入姓名" v-model="name">
							<img v-if="name" src="../assets/select.png" alt="" srcset="">
						</div>
					</div>
					<div class="item">
						<div class="item_name">选择系列</div>
						<div class="inp">
							<select v-model="product">
								<option value="请选择">请选择</option>
								<option value="全部">全部</option>
							</select>
						</div>
					</div>
				</div>

				<div class="items">
					<div class="item">
						<div class="item_name">联系电话</div>
						<div class="inp">
							<input type="text" placeholder="请输入联系电话" v-model="mobile">
							<img v-if="mobile" src="../assets/select.png" alt="" srcset="">
						</div>
					</div>
					<div class="item">
						<div class="item_name">申请区域</div>
						<div class="inp">
							<input type="text" placeholder="请输入申请区域" v-model="qy">
							<img v-if="qy" src="../assets/select.png" alt="" srcset="">
						</div>
					</div>
				</div>

				<div class="items">
					<div class="item">
						<div class="item_name">邮箱</div>
						<div class="inp">
							<input type="text" placeholder="请输入邮箱" v-model="emaile">
							<img v-if="emaile" src="../assets/select.png" alt="" srcset="">
						</div>
					</div>
				</div>

				<div class="items">
					<div class="item" style="width: 100%;">
						<div class="item_name">问题</div>
						<div class="inp">
							<input type="text" placeholder="填写文字" v-model="wt">
							<img v-if="wt" src="../assets/select.png" alt="" srcset="">
						</div>
					</div>
				</div>
				<div class="tips">*我们会尽快以电话或者邮件方式回复，请耐心等待，感谢您的支持</div>
				<div class="form_btn" @click="message">提交</div>
			</div>
			<f-foot />
		</div>
		<div class="mobile_base">
			<m-heard />
			<div class="banner">
				<img src="../assets/mobile/joinBanner.png" alt="">
			</div>
			<p class="m_title">合作加盟</p>
			<p class="m_title_desc">Join us</p>
			<p class="m_qr m60">自希丁创立以来，我们始终是酒店及家具行业领先公司值得信赖的业务合作伙伴。凭借遍布全球的19+个产品研发中心，我们竭力为您的业务量身定制解决方案。</p>
			<img src="../assets/mobile/joinLogos.png" class="joinLogos" alt="" srcset="">
			<img src="../assets/mobile/joinTips.png" class="joinTips" alt="" srcset="">
			<div class="m_b_tips" style="margin: .55rem 0 1.08rem 0;">大中华区联络人：Ronald Tong 童戎戎 <br />Email:
				Ronald.tong@hildinganders.com <br />Mobile: (+86) 186-0096-5686</div>
			<p class="m_title m0">加盟希丁</p>
			<p class="m_title_desc">Join us</p>
			<div class="m_from">
				<div class="m_item">
					<input type="text" placeholder="姓名" v-model="name">
					<img v-if="name" src="../assets/select.png" alt="" srcset="">
				</div>
				<div class="m_item">
					<input type="text" placeholder="联系电话" v-model="mobile">
					<img v-if="mobile" src="../assets/select.png" alt="" srcset="">
				</div>
				<div class="m_item">
					<input type="text" placeholder="电子邮箱" v-model="emaile">
					<img v-if="emaile" src="../assets/select.png" alt="" srcset="">
				</div>
				<div class="m_item">
					<select name="" id="">
						<option value="" disabled selected style="display: none;">选择系列</option>
					</select>
				</div>
				<div class="m_item">
					<input type="text" placeholder="申请区域" v-model="qy">
					<img v-if="qy" src="../assets/select.png" alt="" srcset="">
				</div>
				<div class="m_item" style="height: 2.16rem;margin-bottom: 0;">
					<textarea type="text" placeholder="问题" v-model="wt"></textarea>
					<img v-if="wt" src="../assets/select.png" alt="" srcset="">
				</div>
				<!-- <div class="m_tips">*我们会尽快以电话或者邮件方式回复，请耐心等待，感谢您的支持</div> -->
				<img src="../assets/mobile/fromTips.png" class="fromTips" alt="" srcset="">
				<div class="m_btn" @click="malert">点击发送</div>
			</div>
			<!-- <div class="m_b_tips">招商电话<br />全系列招商<br />销售事业部副总经理 张海平 18688926625<br />渠道拓屐部总监 王鹏飞 13602526569</div> -->
			<m-foot />
		</div>
	</div>
</template>

<script>
	import FHeard from '@/components/FHeard'
	import FFoot from '@/components/FFoot'
	import MHeard from '@/components/MHeard'
	import MFoot from '@/components/MFoot'
	import {join_us} from "@/api";
	export default {
		name: 'Join',
		components: {
			FHeard,
			FFoot,
			MHeard,
			MFoot
		},
		data() {
			return {
				name: '',
				mobile: '',
				qy: '',
				emaile: '',
				wt: '',
				product: '请选择'
			}
		},
		methods: {
			malert() {
				this.$message({
					showClose: true,
					message: '提交成功',
					type: 'success'
				});
			},
			message() {
				if (this.name === '') {
					this.$message({
						showClose: true,
						message: '请输入姓名',
						type: 'error'
					});
					return
				}
				if (this.mobile === '') {
					this.$message({
						showClose: true,
						message: '请输入联系电话',
						type: 'error'
					});
					return
				}
				if (this.emaile === '') {
					this.$message({
						showClose: true,
						message: '请输入邮箱',
						type: 'error'
					});
					return
				}
				if (this.product === '请选择') {
					this.$message({
						showClose: true,
						message: '请选择申请区域',
						type: 'error'
					});
					return
				}
				join_us({
					name: this.name,
					mobile: this.mobile,
					mail: this.emaile,
					product: this.product,
					region: this.qy,
					question: this.wt
				}).then(res => {
					this.$message({
						showClose: true,
						message: '提交成功',
						type: 'success'
					});
					this.name = ''
					this.mobile = ''
					this.emaile = ''
					this.product = '请选择'
					this.qy = ''
					this.wt = ''
				})

			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../css/Join.scss';
</style>
