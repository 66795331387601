<template>
	<div class="product">
		<div class="pc_base">
			<f-heard />
			<img @click="$router.push('/')" src="../assets/menu_logo.png" class="pageLogo" alt="">
<!--			<img src="../assets/product.jpg" class="paoduct" alt="" srcset="">-->
			<div v-html="pageInfo.p_html"></div>
			<f-foot />
		</div>
		<div class="mobile_base">
			<m-heard />
			<div v-html="pageInfo.m_html"></div>
<!--			<img src="../assets/mobile/productBanner.png" class="productBanner" alt="" srcset="">-->
<!--			<div class="abou">-->
<!--				<p class="m_title">床垫</p>-->
<!--				<p class="m_title_desc">Mattress</p>-->
<!--			</div>-->
<!--			<p class="m_qr">拥有一个好床垫是收获良好睡眠至关重要的一步。好的床垫可以帮助你保持舒适的温度，并持续改善睡眠质量。</p>-->
<!--			<p class="m_qr m30">希丁HILDING拥有大量优质床垫，经过科学测试和开发，满足您及家人不同的睡眠需求。欢迎继续阅读，解锁瑞典希丁床垫的睡眠秘诀。</p>-->
<!--			<p class="h60"></p>-->
<!--			<div class="productInfo">-->
<!--				<div class="productLeft">-->
<!--					<img src="../assets/mobile/product1.png" alt="">-->
<!--					<div class="productText a1">-->
<!--						<div class="productName">摇滚</div>-->

<!--						&lt;!&ndash; <p class="productDesc">Rock & Roll 是一种基于两种不同硬度的异形高弹性 HR 泡沫制成的床垫。 它创新配置创建了7个舒适区，可确保提供出色的身体支持。</p> &ndash;&gt;-->
<!--						<img class="prductt" src="../assets/mobile/prductt1.png" alt="">-->
<!--						<img src="../assets/mobile/productb1.png" alt="" class="productb" srcset="">-->

<!--					</div>-->

<!--					<img v-if="showLoad" src="../assets/mobile/product2.png" alt="">-->
<!--					<div v-if="showLoad" class="productText a2">-->
<!--						<div class="productName">摇滚</div>-->

<!--						&lt;!&ndash; <p class="productDesc">Rock & Roll 是一种基于两种不同硬度的异形高弹性 HR 泡沫制成的床垫。 它创新配置创建了7个舒适区，可确保提供出色的身体支持。</p> &ndash;&gt;-->
<!--						<img class="prductt" src="../assets/mobile/prductt2.png" alt="">-->
<!--						<img src="../assets/mobile/productb2.png" alt="" class="productb" srcset="">-->

<!--					</div>-->

<!--					<img v-if="showLoad" src="../assets/mobile/product3.png" alt="">-->

<!--				</div>-->
<!--				<div class="productright ">-->
<!--					<div class="productText a3">-->
<!--						<div class="productName">摇滚</div>-->

<!--						&lt;!&ndash; <p class="productDesc">Rock & Roll 是一种基于两种不同硬度的异形高弹性 HR 泡沫制成的床垫。 它创新配置创建了7个舒适区，可确保提供出色的身体支持。</p> &ndash;&gt;-->
<!--						<img class="prductt" src="../assets/mobile/prductt3.png" alt="">-->
<!--						<img src="../assets/mobile/productb3.png" alt="" class="productb" srcset="">-->

<!--					</div>-->
<!--					<img src="../assets/mobile/product4.png" alt="">-->
<!--					<div v-if="showLoad" class="productText a5">-->
<!--						<div class="productName">摇滚</div>-->

<!--						&lt;!&ndash; <p class="productDesc">Rock & Roll 是一种基于两种不同硬度的异形高弹性 HR 泡沫制成的床垫。 它创新配置创建了7个舒适区，可确保提供出色的身体支持。</p> &ndash;&gt;-->
<!--						<img class="prductt" src="../assets/mobile/prductt4.png" alt="">-->

<!--						<img src="../assets/mobile/productb4.png" alt="" class="productb" srcset="">-->

<!--					</div>-->

<!--					<img v-if="showLoad" src="../assets/mobile/product5.png" alt="">-->
<!--					<div v-if="showLoad" class="productText a5">-->
<!--						<div class="productName">摇滚</div>-->

<!--						&lt;!&ndash; <p class="productDesc">Rock & Roll 是一种基于两种不同硬度的异形高弹性 HR 泡沫制成的床垫。 它创新配置创建了7个舒适区，可确保提供出色的身体支持。</p> &ndash;&gt;-->
<!--						<img class="prductt" src="../assets/mobile/prductt5.png" alt="">-->

<!--						<img src="../assets/mobile/productb5.png" alt="" class="productb" srcset="">-->

<!--					</div>-->

<!--				</div>-->
<!--			</div>-->
<!--			<img v-if="!showLoad" @click="showLoad=true;" src="../assets/mobile/add.png" class="m_add" alt="" srcset="">-->
<!--			<div class="h60" v-if="showLoad"></div>-->
			<m-foot />
		</div>
	</div>
</template>

<script>
	import FHeard from '@/components/FHeard'
	import FFoot from '@/components/FFoot'
	import MHeard from '@/components/MHeard'
	import MFoot from '@/components/MFoot'
	import {page} from "@/api";
	export default {
		name: 'product',
		components: {
			FHeard,
			FFoot,
			MHeard,
			MFoot
		},
		data(){
			return{
				showLoad: true,
				pageInfo: {}
			}
		},
		mounted() {
			this.getPage()
		},
		methods:{
			getPage() {
				page({ p: 'product' }).then(res => {
					console.log(res)
					this.pageInfo = res.data
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../css/Product.scss';
</style>
