<template>
	<div class="news">
		<div class="pc_base">
			<f-heard />
			<img @click="$router.push('/')" src="../assets/menu_logo.png" class="pageLogo" alt="">
			<img src="../assets/newsBanner.png" class="newsBanner" alt="" srcset="">
			<img src="../assets/yun1.png" class="yun" alt="" />
			<p class="fp">了解希丁最新的动态，我们期待与您的相遇</p>
			<img src="../assets/up1.png" class="up" alt="" />

			<div class="newsList">
				<div class="newsItem" v-for="(item,index) in list" :key="index"  @click="go(item.id)">
					<img :src="item.p_img" alt="" srcset="">
					<div class="text">
						<p>{{ item.title }}</p>
						<img src="../assets/newsPre.png" class="newsPre" alt="">
					</div>
				</div>
				<div class="pagination">
					<el-pagination
							background
							layout="prev, pager, next"
							@current-change="handleCurrentChange"
							:current-page.sync="page"
							:total="total">
					</el-pagination>
				</div>
			</div>
			<f-foot />
		</div>
		<div class="mobile_base">
			<m-heard />
			<img src="../assets/mobile/newsBanner.png" class="banner" alt="" srcset="">
			<p class="m_title">新闻</p>
			<p class="m_title_desc">news</p>

			<div class="m60 newsItems">
				<van-list
						v-model="loading"
						:finished="finished"
						finished-text="没有更多了"
						@load="onLoad"
				>
					<div class="newsItem" v-for="(item,index) in m_list" :key="index"  @click="go(item.id)">
						<img :src="item.m_img" alt="" srcset="">
						<div class="text">
							<p>{{ item.title }}</p>
							<img src="../assets/mobile/newsPre.png" class="newsPre" alt="">
						</div>
					</div>
				</van-list>
			</div>
			<m-foot />
		</div>
	</div>
</template>

<script>
	import FHeard from '@/components/FHeard'
	import FFoot from '@/components/FFoot'
	import MHeard from '@/components/MHeard'
	import MFoot from '@/components/MFoot'
	import {news_list} from "@/api";
	export default{
		name: 'news',
		components: {
			FHeard,
			FFoot,
			MHeard,
			MFoot
		},
		data() {
			return {
				page: 1,
				list: [],
				total:0,
				m_list: [],
				loading: false,
				finished: false,
				total_page: 2
			}
		},
		mounted(){
			this.getNewList()
		},
		methods:{
			onLoad() {
				console.log('手机')

				if (this.page >= this.total_page){
					this.finished = true;
				}
				news_list({ page: this.page }).then(res => {
					console.log(res, '手机')
					this.page ++
					this.m_list = [...this.m_list,...res.data.list]
					this.total = res.data.total
					this.total_page = res.data.total_page
					this.loading = false

					// 加载状态结束

					// if (this.t)
				})
			},
			getNewList() {
				news_list({ page: this.page }).then(res => {
					console.log(res)
					this.list = res.data.list
					this.total = res.data.total
				})
			},
			handleCurrentChange(val) {
				console.log(val)
				this.page = val
				this.getNewList()
			},
			go(id){
				this.$router.push('newsDetails?id=' + id);
			}
		}
	}
</script>

<style lang="scss" scoped>
	@import '../css/News.scss';
	.pagination{
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 20px;
	}
	::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active{
		background: #968569;
		color: #fff !important;
	}
	::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover{
		color: #968569;
	}
</style>
