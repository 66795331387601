<template>
	<div @click="setShow(true)">
		<div @click="setShow(true)" style="height: 1.1rem;"></div>
		<div class="MHeard" @click="setShow(true)">
			<img style="opacity: 0;" src="../assets/mobile/m_search.png" class="m_search" alt="" srcset="">
			<img @click.stop="go('/')" src="../assets/mobile/m_logo.png" class="m_logo" alt="" srcset="">
			<img  src="../assets/mobile/m_menu.png" :class="{'m_menus': show}" class="m_menu" alt="" srcset="">
		</div>
		<div class="mask" @click.stop="setShow(false)" v-if="show" :class="{'masks': showInfo}">
			<div class="maskBg"></div>
			<img src="../assets/mobile/m_menu_b.png" class="m_menu_b" alt="" srcset="">
			<div class="menu_items" @click.stop="ret">
				<p @click="go('/')">主页</p>
				<p @click="showNav = true;">关于希丁</p>
				<p @click="go('/product')">产品中心</p>
				<p @click="go('/sleep')">好睡实验室</p>
				<p @click="go('/join')">合作加盟</p>
				<p @click="go('/order')">立即购买</p>
				<p @click="go('/contact')">联系我们</p>
			</div>

			<div class="item_nav" v-if="showNav">
				<div @click="go('/story')" class="nav_item">品牌故事</div>
				<div @click="go('/history')" class="nav_item">历史</div>
				<div @click="go('/Philosophy')" class="nav_item">好睡哲学</div>
				<div @click="go('/news')" class="nav_item">新闻</div>
			</div>
		</div>

	</div>

</template>

<script>
	export default {
		data(){
			return {
				show: false,
				showInfo: false,
				showNav: false
			}
		},
		methods:{
			go(url){
				this.$router.push(url)
			},
			ret(){

			},
			showSearch(flag){
				// console.log(11);
				//this.$parent.serch = true;
				this.$store.state.search = flag;
			},
			setShow(flag){
				if(flag){
					this.show = true;
					// setTimeout(()=>{
						this.showInfo = true;
					// }, 50)
				}else{
					this.showInfo = false;
					// setTimeout(()=>{
						this.show = false;
					// }, 500)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.mask{
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		opacity: 0;
		z-index: 99;
		// background: rgba(0, 51, 95, .6);
		transition: all .5s;
		.item_nav{
			position: absolute;
			right: 3.87rem;
			top: 2.55rem;
			width: 2.54rem;
			height: 3.92rem;
			background: #00335f;
			padding-top: .13rem;
			box-sizing: border-box;
			.nav_item{
				font-size: 12px;
				color: #FFFFFF;
				font-family: "AlibabaPuHuiTiM";
				line-height: .9rem;
				letter-spacing: .08rem;
				text-align: center;
			}
		}
		// filter: blur(.06rem);
		.maskBg{
			position: absolute;
			left: 0;
			top: 0;
			width: 100vw;
			height: 100vh;
			background: rgba(0, 51, 95, .6);
			backdrop-filter: blur(6px);
		}
		&.masks{
			opacity: 1;
		}
		.m_menu_b{
			position: absolute;
			right: .4rem;
			top: .4rem;
			width: .32rem;
			width: .32rem;
			z-index: 2;
		}
		.menu_items{
			width: 3.49rem;
			height: 7.42rem;
			background-image: url('../assets/mobile/m_mune_bg.png');
			background-size: 100% 100%;
			position: absolute;
			right: .4rem;
			top: 1.02rem;
			padding: 1.02rem .51rem .6rem .53rem;
			font-size: .20rem;
			color: #fff;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: space-between;
			font-family: "AlibabaPuHuiTiM";
			letter-spacing: 4px;
			z-index: 2;
		}
	}
	.MHeard{
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		background: #eee;
		height: 1.1rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		box-sizing: border-box;
		padding: 0 .4rem;
		z-index: 8;
		.m_menu{
			width: .32rem;
			height: .32rem;
			&.m_menus{
				opacity: 0;
			}
		}
		// .m_menu_b{
		// 	width: .32rem;
		// 	height: .32rem;
		// 	position: absolute;
		// 	right: .4rem;
		// 	top: .5rem;
		// }

		.m_search{
			width: .32rem;
			height: .32rem;
		}

		.m_logo{
			width: 1.84rem;
			height: .49rem;
		}

	}
	@media screen and (orientation: landscape) {
		/* 修改需要调整的元素的样式 */
		.menu_items{
			height: 3rem !important;
		}
		.mask .menu_items{
			padding-top: .5rem;
			padding-bottom: .2rem;
		}
	}
</style>
